import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { step1AirConditionerTypeMock, step1ConfigurationMock, step1ProducerMock, step2AdditionalFilterMock, step2ColorMock, step2CommunicationModuleMock, step2ControlMock, step2CoverTypeMock, step2ExternalAggregateMock, step2FunctionsMock, step2PowerMock, step2TypeMock, step3ProductsMock } from "../mocks/order-creator.mock";
import { OrderCreatorOption, OrderCreatorPossibleOptionsResp } from "../models/order-creator-option.model";
import { OrderCreatorState } from "../models/order-creator-state.model";

@Injectable({
	providedIn: "root",
})
export class OrderCreatorService {
	constructor (
		private http: HttpClient
	) {}

	private orderCreatorStateSubject: BehaviorSubject<OrderCreatorState> = new BehaviorSubject({
		step1: {
			selectedOptions: [],
			possibleOptions: []
		},
		step2: {
			selectedOptions: [],
			possibleOptions: []
		},
		step3: {
			orderProducts: []
		}
	})
	orderCreatorState = this.orderCreatorStateSubject.asObservable()

	setStep1PossibleOptions = (data: OrderCreatorOption[]) => {
		this.orderCreatorStateSubject.next({
			...this.orderCreatorStateSubject.value,
			step1: {
				selectedOptions: this.orderCreatorStateSubject.value.step1.selectedOptions,
				possibleOptions: data,
			}
		})
	}

	setStep1SelectedOptions = (data: OrderCreatorOption[]) => {
		this.orderCreatorStateSubject.next({
			...this.orderCreatorStateSubject.value,
			step1: {
				selectedOptions: data,
				possibleOptions: this.orderCreatorStateSubject.value.step1.possibleOptions,
			}
		})
	}

	setStep2PossibleOptions = (data: OrderCreatorOption[]) => {
		this.orderCreatorStateSubject.next({
			...this.orderCreatorStateSubject.value,
			step2: {
				selectedOptions: this.orderCreatorStateSubject.value.step2.selectedOptions,
				possibleOptions: data
			}
		})
	}

	setOrderProducts = (data: any) => {
		this.orderCreatorStateSubject.next({
			...this.orderCreatorStateSubject.value,
			step3: {
				orderProducts: data
			}
		})
	}

	setStep2SelectedOptions = (data: OrderCreatorOption[]) => {
		this.orderCreatorStateSubject.next({
			...this.orderCreatorStateSubject.value,
			step2: {
				selectedOptions: data,
				possibleOptions: this.orderCreatorStateSubject.value.step2.possibleOptions,
			}
		})
	}

	processOrderCreatorStep2 = (body: any): Observable<any> => {
		return of(step3ProductsMock) //returning mock for now
		// return this.http.post('https://someurl.pl/', body);
	}

	processOrderCreatorStep1 = (body: any): Observable<OrderCreatorPossibleOptionsResp> => {
		return of({
			color: step2ColorMock,
			function: step2FunctionsMock,
			type: step2TypeMock,
			coverType: step2CoverTypeMock,
			power: step2PowerMock,
			control: step2ControlMock,
			communicationModule: step2CommunicationModuleMock,
			additionalFilter: step2AdditionalFilterMock,
			externalAggregate: step2ExternalAggregateMock,
		}) //returning mock for now
		return this.http.post('https://someurl.pl/', body);
	}

	getOrderCreatorInitialData = (): Observable<OrderCreatorPossibleOptionsResp> => {
		return of({
			configuration: step1ConfigurationMock,
			producer: step1ProducerMock,
			airConditionerType: step1AirConditionerTypeMock,
		}) //returning mock for now
		// return this.http.get('https://someurl.pl/');
	}

}