import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'apollo-link';
import { OrderDetails } from '../models/orderDetails.model';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SupplyOrderService {

  private createOrderMutation = gql`
    mutation CreateOrder($additionalInfo: AdditionalInfoInput!,
    $address: AddressInput!,
    $products: [ProductInput]!) {
        createOrder(
          products: $products,
          address: $address,
          additionalInfo: $additionalInfo
        ) {
          orderProducts {
            order {
              customer {
                firstName
                lastName
                email
              }
              address {
                street
                city
                postcode
              }
            }
            product {
              id
              index
              producerIndex
              unit
            }
            qty
            producerIndex
            name
            netPrice
            grossPrice
            producerNetPrice
            vat
            unit
          }
        }
      }
  `;

  private ordersQuery = gql`
    query {
      orders {
      id
      customer {
        company {
          name
        }
      }
      totalPrice
      status
      createdAt
      orderproductSet {
        name
        netPrice
        qty
      }
      orderProducts {
        product {
          id
          index
          name
          netPrice
        }
        qty
      }
    }
  }`;

  public supplyOrders: any;

  private orderDetailsSource: Subject<OrderDetails> = new Subject()

  public orderDetails = this.orderDetailsSource;

  constructor(private apollo: Apollo) { }

  public createOrder(products, orderDetails: OrderDetails): any {
    // const products = [{ index: '005', qty: 1, additionalInfo: '' }, { index: '00900138', qty: 1, additionalInfo: 'jeden czerwony' }];
    const address = { street: orderDetails.street, city: orderDetails.city, postcode: orderDetails.postCode };
    const additionalInfo = { paymentType: orderDetails.comments }; // should it be paymentType because of backend field?
    // return {};
    return this.apollo.mutate({ mutation: this.createOrderMutation, variables: { products, address, additionalInfo } });
  }

  public getOrders() {
    return this.apollo
      .query({ query: this.ordersQuery })
      .pipe(
        map(this.mapResponseToOrders),
        tap(this.saveSupplyOrdersInService),
      );
  }

  public saveAddress = (orderDetails: OrderDetails): void => {
    this.orderDetailsSource.next(orderDetails);
  }

  public deleteAddress = (): void => {
    this.orderDetailsSource.next(new OrderDetails());
  }

  private mapResponseToOrders(response) {
    return response.data.orders.map((item: any) => {
        const container: any = {};
        const date = new Date(item.createdAt);
        container.id = Number(item.id);
        container.name = item.customer.company.name;
        container.totalPrice = item.totalPrice;
        container.createdAt = date;
        container.status = item.status;
        container.products = item.orderproductSet;
        container.orderProducts = item.orderProducts;

        return container;
      });
  }

  saveSupplyOrdersInService(supplyOrders) {
    this.supplyOrders = supplyOrders;
  }

  getSupplyOrders(){
    if(this.supplyOrders) {
      return Observable.of(this.supplyOrders)
    } else {
      return this.getOrders();
    }
  }

}
