<div class="view-wrapper">
  <div class="my-account-container">
    <p-card>
      <div class="account-details-wrapper">
        <div class="account-image-wrappper">
          <img class="img-fluid" src="https://via.placeholder.com/150" alt="" />
        </div>
        <div class="account-data">
          <h1>{{ me?.name }}</h1>
          <!-- TODO: What about other data here? -->
          <p class="dicount-group">
            Mój handlowiec: {{ me?.salesperson.firstName }}
            {{ me?.salesperson.lastName }}
          </p>
          <p class="dicount-group">
            Mój kredyt kupiecki: {{ me?.globalLimit }}
          </p>
          <p class="dicount-group">
            Mój kredyt kupiecki do wykorzystania: {{ me?.currentLimit }}
          </p>
        </div>
      </div>
    </p-card>
    <p-card>
      <div class="password-change">
        <p>Zmiana hasła:</p>
        <form (ngSubmit)="changePassword()" [formGroup]="changePasswordForm">
          <div class="p-fluid p-grid">
            <!-- <div class="p-field p-col-12">
              <span class="p-float-label">
                <input
                  type="text"
                  id="oldPassword"
                  pInputText
                  formControlName="oldPassword"
                />
                <label for="inputtext">Stare hasło:</label>
              </span>
            </div> -->

            <div class="p-field p-col-12">
              <span class="p-float-label">
                <input
                  id="newPassword"
                  type="password"
                  pPassword
                  formControlName="newPassword"
                />
                <label for="passgroup">Nowe hasło:</label>
              </span>
            </div>

            <div class="p-field p-col-12">
              <span class="p-float-label">
                <input
                  id="confirmNewPassword"
                  type="password"
                  pPassword
                  formControlName="confirmNewPassword"
                />
                <label for="passgroup">Powtórz hasło:</label>
              </span>
            </div>
          </div>
          <button class="primary" mat-raised-button>Zmień</button>
        </form>
      </div>
    </p-card>
  </div>
  <mat-divider class="black"></mat-divider>

  <p-toast position="center" key="cc"></p-toast>
</div>
