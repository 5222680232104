import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { ProductType } from "../../../generated/graphql";
import { ProductsService } from "../../services/products.service";
import { mapAndReduce } from "../../helpers";
import { CONSTS_ROUTES } from "src/app/consts";
import { CartService } from "./../../services/cart.service";
import { ProductToOrdersType } from "./../../../generated/graphql";
import { SupplyOrderService } from "./../../services/supply-order.service";
import { OrderDetails } from "src/app/models/orderDetails.model";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { UserService } from "src/app/services/user.service";
//primeng
import { ConfirmationService, MessageService } from "primeng/api";
import { AutoComplete } from "primeng/autocomplete";

@Component({
  selector: "app-new-supply-order",
  templateUrl: "./new-supply-order.component.html",
  styleUrls: ["./new-supply-order.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService, ConfirmationService],
})
export class NewSupplyOrderComponent implements OnInit {
  public productSuggestions: ProductType[];
  public selectedProducts: ProductType[] = [];
  public selectedProductsToOrders: ProductToOrdersType[] = [];
  public msgs = [];
  public routes = CONSTS_ROUTES;
  public commentsFormGroup: FormGroup;
  public addressFormGroup: FormGroup;
  public isDialogVisible: boolean = false;
  public savedAddress: OrderDetails;
  private subscription: Subscription = new Subscription();
  public me: any;

  public isBlockedAccount: boolean;
  public hasLimitBlokcked: boolean = false;
  public isButtonDisabled = true;

  constructor(
    private productsService: ProductsService,
    private supplyOrder: SupplyOrderService,
    private messageService: MessageService,
    private localStorageService: LocalStorageService,
    private cartService: CartService,
    private fb: FormBuilder,
    private userSerwice: UserService,
    private confirmationService: ConfirmationService
  ) {}

  @ViewChild("addProduct") addProduct: AutoComplete;

  ngOnInit() {
    this.createFormGroups();
    this.setProductsFromStorage();
    this.listenForSavedAddressChanges();
    this.checkStorageForAddress();
    this.getMyAccount();
  }

  private async getMyAccount() {
    const data: any = await this.userSerwice.getMyAccount();
    console.log(data);

    if (data) {
      this.me = data;
      this.isBlockedAccount = data.isBlocked;

      if (this.isBlockedAccount) {
        this.messageService.add({
          key: "bc",
          severity: "error",
          summary: "Blokada konta",
          detail:
            "brak możliwości złożenia zamówienia, ureguluj nieopłacone faktury",
        });
      }
      console.log("1===checkLimitBlocked");
      this.checkLimitBlocked();
    }
  }

  private checkLimitBlocked() {
    const vatSum = this.getFinancialSummary().products.grossSum;
    const val: number = this.me.currentLimit - Number(vatSum);
    console.log(val);

    if (val < 0) {
      this.hasLimitBlokcked = true;
      console.log("M===checkLimitBlocked");
      this.messageService.add({
        key: "bc",
        severity: "error",
        summary: "Blokada zamówienia",
        detail: "Twoje zamówienie przekracza dostępny limit",
      });
    } else {
      this.hasLimitBlokcked = false;
    }
    this.checkButtonDisabled();
  }

  createFormGroups = (): void => {
    this.addressFormGroup = this.fb.group({
      city: ["", Validators.required],
      postCode: [
        "",
        [Validators.required, Validators.pattern(/^[0-9]{2}-[0-9]{3}/)],
      ],
      street: ["", Validators.required],
    });
    this.commentsFormGroup = this.fb.group({
      comments: [""],
    });
  };

  listenForSavedAddressChanges = (): void => {
    this.subscription.add(
      this.supplyOrder.orderDetails.subscribe((data) => {
        if (data) {
          this.savedAddress = data;
        }
      })
    );
  };

  checkStorageForAddress = (): void => {
    if (!this.savedAddress) {
      this.savedAddress = this.localStorageService.get("address");
    }
  };

  getSavedAddressToDisplay = (): string => {
    return `${this.savedAddress.street}, ${this.savedAddress.city}, ${this.savedAddress.postCode}`;
  };

  private checkButtonDisabled() {
    console.log("checkButtonDisabled");

    if (!!this.selectedProductsToOrders.length) {
      this.isButtonDisabled = false;
      if (this.isBlockedAccount) {
        this.isButtonDisabled = true;
      } else {
        if (this.hasLimitBlokcked) {
          this.isButtonDisabled = true;
        }
      }
    } else {
      this.isButtonDisabled = true;
    }
    console.error("=== blokowany btn: " + this.isButtonDisabled + " ===");
  }

  showAddAddressDialog = (): void => {
    this.setAddressFormData();
    this.isDialogVisible = true;
  };

  setAddressFormData = (): void => {
    if (this.savedAddress) {
      this.addressFormGroup.controls["city"].setValue(this.savedAddress.city);
      this.addressFormGroup.controls["street"].setValue(
        this.savedAddress.street
      );
      this.addressFormGroup.controls["postCode"].setValue(
        this.savedAddress.postCode
      );
    } else {
      this.addressFormGroup.controls["city"].setValue("");
      this.addressFormGroup.controls["street"].setValue("");
      this.addressFormGroup.controls["postCode"].setValue("");
    }
  };

  saveAddress = (): void => {
    this.supplyOrder.saveAddress(this.getOrderDetails());
    this.localStorageService.set("address", this.getOrderDetails());
    this.isDialogVisible = false;
  };

  deleteAddress = (): void => {
    this.supplyOrder.deleteAddress();
    this.localStorageService.remove("address");
    this.savedAddress = null;
    this.setAddressFormData();
  };

  getAddAddressBtnLabel = (): string => {
    return this.savedAddress ? "Edytuj adres" : "Dodaj adres";
  };

  private getOrderDetails = (): OrderDetails => {
    const orderDetails: OrderDetails = {
      city: this.addressFormGroup.value.city,
      postCode: this.addressFormGroup.value.postCode,
      street: this.addressFormGroup.value.street,
      comments: this.commentsFormGroup.value.comments,
    };
    return orderDetails;
  };

  private async setProductsFromStorage() {
    const productsToOrder =
      await this.cartService.getProductsToOrderInStorage();
    // console.log("productsToOrder", productsToOrder);
    if (productsToOrder) {
      this.selectedProductsToOrders = productsToOrder;
    }
  }

  remove(type: string, index: string) {
    const variableName =
      type === "product" ? "selectedProductsToOrders" : "selectedServices";
    this[variableName] = this[variableName].filter(
      (product) => product.index !== index
    );
    // console.log(this.selectedProductsToOrders);
    this.setProductsToOrderInStorage();
  }

  searchProducts(event) {
    console.log("searchProducts", event);

    const searchQueryUppercase = event.query.toUpperCase();
    this.productsService.getProduct(searchQueryUppercase).subscribe((res) => {
      this.productSuggestions = res.data.search;
      // console.log('sugestion product', this.productSuggestions);
    });
  }

  public async selectItem(item) {
    // console.log("select item", item);
    const msg = await this.cartService.addProductToCart(item);
    this.messageService.add(msg);
    await this.setProductsFromStorage();
    console.log("2===checkLimitBlocked");
    this.checkLimitBlocked();
    this.selectedProducts = [];
  }

  orderProducts() {
    if (!this.isOrderProductsQtyBiggerThanWarehouseQty()) {
      this.createSupplyOrder();
    } else if (this.isNonVirtualWarehouseProductsQtyEnough()) {
      this.showDialogForNonVirtualProductsQty();
    } else {
      this.confirmationService.confirm({
        message:
          'Zamawiana ilość produktów jest wyższa niż stan magazynowy. Handlowiec potwierdzi ilość i cenę. Aby potwierdzić kliknij "Kontynuuj"',
        acceptLabel: "Kontynuuj",
        rejectLabel: "Anuluj",
        accept: () => {
          this.createSupplyOrder();
        },
      });
    }
  }

  showDialogForNonVirtualProductsQty = (): void => {
    const products = this.getNonVirtualWarehouseProductsWithNotEnoughQty();
    this.confirmationService.confirm({
      message: `Twoje zamówienie zawiera produkty, których zamawiana ilość przekracza stan magazynowy. Zmniejsz ilość sztuk wymienionych produktów lub 
        usuń je z zamówienia aby kontynuować. <br> Produkty: ${this.displayProductNames(
          products
        )}`,
      acceptLabel: "Powrót",
      rejectVisible: false,
    });
  };

  displayProductNames = (products: ProductToOrdersType[]): string => {
    const productsNames = products.map((product) => product.name);
    const namesToDisplay = productsNames.toString().replace(/,/g, ", ");
    return namesToDisplay;
  };

  isOrderProductsQtyBiggerThanWarehouseQty = (): boolean => {
    const productsWithBiggerQty = this.selectedProductsToOrders.filter(
      (product) => product.qty > product.inStock
    );
    return !!productsWithBiggerQty.length;
  };

  isNonVirtualWarehouseProductsQtyEnough = () => {
    const productsWithBiggerQtyAndNonVirtual =
      this.selectedProductsToOrders.filter(
        (product) => product.qty > product.inStock && !product.isVirtual
      );
    return !!productsWithBiggerQtyAndNonVirtual.length;
  };

  getNonVirtualWarehouseProductsWithNotEnoughQty =
    (): ProductToOrdersType[] => {
      const products = this.selectedProductsToOrders.filter(
        (product) => !product.isVirtual && product.qty > product.inStock
      );
      return products;
    };

  onFocusInput() {
    // console.log("onFocusInput");
  }

  public setProductsToOrderInStorage(ev?) {
    console.log("setProductsToOrderInStorage", ev);
    this.cartService.setProductsToOrderInStorage(this.selectedProductsToOrders);
    console.log("3===checkLimitBlocked");

    this.checkLimitBlocked();
  }

  public keypress(ev) {
    console.log("keypress", ev);
    // this.setProductsToOrderInStorage();
    this.checkLimitBlocked();
  }

  public handleInputBlur(ev) {
    console.log("handleInputBlur", ev);
  }

  public handleInputFocus(ev) {
    console.log("handleInputFocus", ev);
  }

  getFinancialSummary() {
    const summary = {
      products: {
        netSum: Number(
          mapAndReduce(this.selectedProductsToOrders, "netPrice")
        ).toFixed(2),
        grossSum: Number(
          mapAndReduce(this.selectedProductsToOrders, "grossPrice")
        ).toFixed(2),
        // tslint:disable-next-line:max-line-length
        vatSum: Number(
          mapAndReduce(this.selectedProductsToOrders, "grossPrice") -
            mapAndReduce(this.selectedProductsToOrders, "netPrice")
        ).toFixed(2),
        producerNetPriceSum: mapAndReduce(
          this.selectedProductsToOrders,
          "producerNetPrice"
        ),
        netProfit:
          mapAndReduce(this.selectedProductsToOrders, "netPrice") -
          mapAndReduce(this.selectedProductsToOrders, "producerNetPrice"),
      },
    };
    // console.log("Suma brutto", summary.products.grossSum);
    return summary;
  }

  createSupplyOrder() {
    // const productIndexes = this.selectedProducts.map(prod => prod.index);
    const productIndexes = this.selectedProductsToOrders.map((item: any) => {
      const container: any = {};
      container.index = item.index;
      container.qty = item.qty;
      container.additionalInfo = item.additionalInfo;
      return container;
    });
    // console.log("selectedProductsToOrders", this.selectedProductsToOrders);

    // console.log("Produkty", productIndexes);
    /**/
    this.supplyOrder
      .createOrder(productIndexes, this.getOrderDetails())
      .subscribe(
        (res) => {
          console.log("createOrder | res", res);
          if (res.errors) {
            const msg = res.errors[0].message;
            this.messageService.add({
              key: "bc",
              severity: "error",
              summary: "Zamówienie",
              detail: msg,
            });
            return {};
          }
          if (res.data.createOrder.orderProducts.length) {
            this.messageService.add({
              key: "bc",
              severity: "success",
              summary: "Zamówienie",
              detail: "wysłane i oczekuje na weryfikacje",
            });
            this.clearOrder();
          } else {
            this.messageService.add({
              key: "bc",
              severity: "error",
              summary: "Zamówienie",
              detail: "nie wysłane",
            });
          }
        },
        (err) => {
          console.log("error |", err.message);
        }
      );
  }

  private clearOrder() {
    this.selectedProducts = [];
    this.selectedProductsToOrders = [];
    this.addressFormGroup.reset();
    this.deleteAddress();
    this.commentsFormGroup.reset();
    this.setProductsToOrderInStorage();
  }

  public convertIndex(index) {
    const indexNew = index.replace("/", "_");
    // console.log(index, indexNew);
    return indexNew;
  }
}
