<div class="new-supply-order-view">
  <div class="col-12">
    <p-toast position="top-center" key="tc"></p-toast>
    <!-- <p-messages [(value)]="msgs"></p-messages> -->

    <p>Dodaj towar</p>

    <div class="p-fluid p-my-3">
      <!-- <p-autoComplete
       [(ngModel)]="brand" 
       [suggestions]="filteredBrands" 
       (completeMethod)="filterBrands($event)" 
       [size]="30"
      [minLength]="1" 
      placeholder="Hint: type 'v' or 'f'"
       [dropdown]="true"> -->

      <!-- <p-autoComplete
        id="addProduct"
        (onSelect)="selectItem($event)"
        [(ngModel)]="selectedProducts"
        [suggestions]="productSuggestions"
        (completeMethod)="searchProducts($event)"
        [delay]="300"
        [dataKey]="'index'"
        [autoHighlight]="true"
        field="name"
        placeholder="Wpisz nazwę lub indeks produktu"
        [inputStyleClass]="'full-width'"
      > -->
      <p-autoComplete
        id="addProduct"
        [(ngModel)]="selectedProducts"
        [suggestions]="productSuggestions"
        (completeMethod)="searchProducts($event)"
        (onSelect)="selectItem($event); addProduct.hide()"
        [dropdown]="true"
        [size]="5"
        [minLength]="1"
        [dataKey]="'index'"
        field="name"
        placeholder="Wpisz nazwę lub indeks produktu"
      >
        <ng-template let-suggestion pTemplate="item">
          <div>
            {{ suggestion.index }}, {{ suggestion.name }} -
            {{ suggestion.inStock }} {{ suggestion.unit }}
          </div>
        </ng-template>
        <ng-template let-value pTemplate="selectedItem">
          <div>
            <p>{{ value.name }} ({{ value.index }})</p>
          </div>
        </ng-template>
      </p-autoComplete>
    </div>

    <p-table
      *ngIf="selectedProductsToOrders.length"
      [value]="selectedProductsToOrders"
      class="p-my-3"
    >
      <ng-template pTemplate="header">
        <tr class="ig-table-tr">
          <th scope="col" width="10%">id</th>
          <th scope="col" width="26%">nazwa</th>
          <th scope="col" width="10%">cena katalogowa</th>
          <th scope="col" width="8%">magazyn</th>
          <th scope="col" width="8%">cena netto</th>
          <th scope="col" width="5%">VAT</th>
          <th scope="col" width="10%">ilość</th>
          <th scope="col" width="5%"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr class="ig-table-tr">
          <td
            [routerLink]="[
              '../' + routes.PRODUCT.path + '/' + convertIndex(product.index)
            ]"
            [queryParams]="{ index: product.index }"
            class="clickable p-text-nowrap p-text-truncate"
          >
            {{ product.index }}
          </td>
          <td
            [routerLink]="[
              '../' + routes.PRODUCT.path + '/' + convertIndex(product.index)
            ]"
            [queryParams]="{ index: product.index }"
            class="clickable p-text-nowrap p-text-truncate"
          >
            {{ product.name }}
          </td>
          <td>{{ product.producerNetPrice | number }}</td>
          <td *ngIf="!product.isVirtual">
            {{ product.inStock }} {{ product.unit }}
          </td>
          <td *ngIf="product.isVirtual">na zamówienie</td>
          <td>{{ product.netPrice | number }}</td>
          <td>{{ product.vat }}</td>
          <td>
            <p-inputNumber
              styleClass="ig-inputnumber"
              [showButtons]="true"
              [min]="1"
              [(ngModel)]="product.qty"
              (onInput)="setProductsToOrderInStorage($event)"
              (onBlur)="setProductsToOrderInStorage($event)"
            >
            </p-inputNumber>
          </td>
          <td>
            <button
              pButton
              pRipple
              type="button"
              icon="pi pi-minus-circle"
              class="p-button-rounded p-button-text p-button-danger"
              (click)="remove('product', product.index)"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <p *ngIf="!selectedProductsToOrders.length">Nie dodano żadnych produktów</p>

    <mat-divider class="black p-my-3"></mat-divider>

    <p>Dodaj adres</p>

    <p-card>
      <p *ngIf="!savedAddress">Brak dodanego adresu</p>
      <p *ngIf="savedAddress">
        {{ getSavedAddressToDisplay() }}
      </p>
    </p-card>

    <p-dialog title="Dodaj adres" [(visible)]="isDialogVisible">
      <form [formGroup]="addressFormGroup" class="form-wrapper">
        <div class="address-wrapper">
          <div class="address-row first-row">
            <input
              type="text"
              class="street"
              placeholder="Ulica i numer"
              pInputText
              formControlName="street"
            />
          </div>
          <div class="address-row">
            <input
              type="text"
              class="city"
              placeholder="Miasto"
              pInputText
              formControlName="city"
            />
            <input
              type="text"
              class="postCode"
              placeholder="Kod pocztowy"
              pInputText
              formControlName="postCode"
            />
          </div>
        </div>
        <div class="add-address-btn-wrapper">
          <p-button
            [disabled]="!addressFormGroup.valid"
            (onClick)="saveAddress()"
            label="Zapisz adres"
          >
          </p-button>
        </div>
      </form>
    </p-dialog>

    <div class="add-address-btn-wrapper">
      <p-button
        (onClick)="showAddAddressDialog()"
        [label]="getAddAddressBtnLabel()"
      >
      </p-button>
      <button
        pButton
        type="button"
        *ngIf="savedAddress"
        class="p-button-secondary"
        (click)="deleteAddress()"
        label="Usuń adres"
      ></button>
    </div>

    <mat-divider class="black p-my-3"></mat-divider>

    <p>Dodaj uwagi do zamówienia</p>

    <form class="form-wrapper" [formGroup]="commentsFormGroup">
      <div class="comments-wrapper">
        <textarea
          pInputTextarea
          formControlName="comments"
          [rows]="5"
          placeholder="Uwagi do zamówienia"
        ></textarea>
      </div>
    </form>

    <mat-divider class="black p-my-3"></mat-divider>

    <p-confirmDialog
      header="Informacja"
      icon="pi pi-exclamation-triangle"
    ></p-confirmDialog>

    <div
      *ngIf="isBlockedAccount"
      class="p-pb-4 p-text-bold p-d-block p-print-none"
    >
      Blokada konta, brak możliwości złożenia zamówienia, ureguluj nieopłacone
      faktury.
    </div>

    <p-button
      class="common add-product"
      (onClick)="orderProducts()"
      [disabled]="isButtonDisabled"
      label="Złóż zamówienie"
    >
    </p-button>

    <div class="summary p-shadow-4" *ngIf="selectedProductsToOrders.length">
      <table class="table table-striped">
        <tbody>
          <tr>
            <td>Suma netto</td>
            <td class="p-text-right">
              {{ getFinancialSummary().products.netSum }}
            </td>
          </tr>
          <tr>
            <td>Suma VAT</td>
            <td class="p-text-right">
              {{ getFinancialSummary().products.vatSum }}
            </td>
          </tr>
          <tr>
            <td>Suma brutto</td>
            <td class="p-text-right">
              <strong>{{ getFinancialSummary().products.grossSum }}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p-toast position="center" key="bc"></p-toast>
  </div>
</div>
