import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ProductType} from '../../../generated/graphql';
import {ProductsService} from '../../services/products.service';
import {mapAndReduce} from '../../helpers';

@Component({
  selector: 'app-new-end-customer-offer',
  templateUrl: './new-end-customer-offer.component.html',
  styleUrls: ['./new-end-customer-offer.component.scss']
})
export class NewEndCustomerOfferComponent implements OnInit {
  public endCustomerOfferForm: FormGroup;
  public newServiceForm: FormGroup;
  public productSuggestions: ProductType[];
  public selectedProducts: any[] = [];
  public selectedServices = [];
  public VATRates = [
    {name: 'VAT 23 %', rate: 23},
    {name: 'VAT 8 %', rate: 8},
    {name: 'VAT 0 %', rate: 0},
  ];

  constructor(private productsService: ProductsService) {
    this.endCustomerOfferForm = new FormGroup({
      isCompany: new FormControl(),
      firstName: new FormControl(),
      lastName: new FormControl(),
      mail: new FormControl(),
      companyName: new FormControl(),
      companyTaxNumber: new FormControl(),
    });

    this.newServiceForm = new FormGroup({
      newServiceName: new FormControl(),
      newServiceNetPrice: new FormControl(),
      newServiceVATRate: new FormControl(this.VATRates[0].rate),
    });
  }

  ngOnInit() {
  }

  getFinancialSummary() {
    return {
      products: {
        netSum: mapAndReduce(this.selectedProducts, 'netPrice'),
        grossSum: mapAndReduce(this.selectedProducts, 'grossPrice'),
        vatSum: mapAndReduce(this.selectedProducts, 'grossPrice') - mapAndReduce(this.selectedProducts, 'netPrice'),
        producerNetPriceSum: mapAndReduce(this.selectedProducts, 'producerNetPrice'),
        netProfit: mapAndReduce(this.selectedProducts, 'netPrice') - mapAndReduce(this.selectedProducts, 'producerNetPrice')
      },
      services: {
        netSum: mapAndReduce(this.selectedServices, 'netPrice'),
        grossSum: mapAndReduce(this.selectedServices, 'grossPrice'),
        vatSum: mapAndReduce(this.selectedServices, 'grossPrice') - mapAndReduce(this.selectedServices, 'netPrice'),
        producerNetPriceSum: mapAndReduce(this.selectedServices, 'producerNetPrice'),
        netProfit: mapAndReduce(this.selectedProducts, 'netPrice') - mapAndReduce(this.selectedProducts, 'producerNetPrice')
      }
    };
  }

  searchProducts(event) {
    const searchQueryUppercase = event.query.toUpperCase();
    this.productsService.getProduct(searchQueryUppercase).subscribe(res => {
      this.productSuggestions = res.data.search;
    });
  }

  addService() {
    const formValue = this.newServiceForm.value;
    const newService = {
      index: Math.round(Date.now() * (Math.random() + 1)),
      name: formValue.newServiceName,
      netPrice: formValue.newServiceNetPrice,
      vat: formValue.newServiceVATRate,
      grossPrice: formValue.newServiceNetPrice + (formValue.newServiceNetPrice * formValue.newServiceVATRate / 100),
    };
    this.selectedServices.push(newService);
    this.newServiceForm.reset();
  }

  remove(type: string, index: string) {
    const variableName = type === 'product' ? 'selectedProducts' : 'selectedServices';
    this[variableName] = this[variableName].filter(product => product.index !== index);
  }

  setQuantity(type: string, index: number, $event) {
    const quantity = parseInt($event.target.value, 10);
    const variableName = type === 'product' ? 'selectedProducts' : 'selectedServices';
    const toReplaceObject = {...this[variableName].find(el => el.index === index), quantity};
    const toReplaceIndex =  this[variableName].findIndex(el => el.index === index);
    this[variableName][toReplaceIndex] = toReplaceObject;

  }

  createEndCustomerOffer() {
    const productIndexes = this.selectedProducts.map(prod => ({index: prod.index, quantity: prod.quantity}));
    const customer = this.endCustomerOfferForm.value;

    // console.log('Produkty', productIndexes);
    // console.log('Uslugi', this.selectedServices);
    // console.log('Klient', customer);
  }

}
