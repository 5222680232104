export const endCustomerOffers = [
  {
    "id": "5d87793a95f836cfc48d7f25",
    "createdAt": 1433763144,
    "changedAt": 1003301590,
    "createdBy": "Martinez Navarro",
    "salesRepresentative": "incididunt",
    "customer": "cillum",
    "value": 3700.58,
    "amountPaid": 1835.68,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "cancelled"
  },
  {
    "id": "5d87793a2b62a7e9bae8d513",
    "createdAt": 836916910,
    "changedAt": 1436473382,
    "createdBy": "Bobbi Joyce",
    "salesRepresentative": "velit",
    "customer": "enim",
    "value": 2614.4,
    "amountPaid": 3025.77,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "cancelled"
  },
  {
    "id": "5d87793ac5228448a5ab2b8b",
    "createdAt": 1413369184,
    "changedAt": 1348003409,
    "createdBy": "Rhonda Cabrera",
    "salesRepresentative": "ea",
    "customer": "eiusmod",
    "value": 2880.11,
    "amountPaid": 3006.06,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "cancelled"
  },
  {
    "id": "5d87793afb97b9b5127784fa",
    "createdAt": 840009223,
    "changedAt": 1376409132,
    "createdBy": "Christa Carson",
    "salesRepresentative": "eu",
    "customer": "sint",
    "value": 3614.28,
    "amountPaid": 3639.9,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793a3c65dc3f458e43ca",
    "createdAt": 1564848002,
    "changedAt": 1105189949,
    "createdBy": "Campos Cote",
    "salesRepresentative": "esse",
    "customer": "in",
    "value": 3924.21,
    "amountPaid": 3371.33,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793aaacf023b19541625",
    "createdAt": 1275550302,
    "changedAt": 1193998665,
    "createdBy": "Price Barlow",
    "salesRepresentative": "enim",
    "customer": "mollit",
    "value": 1840.73,
    "amountPaid": 1297.08,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "cancelled"
  },
  {
    "id": "5d87793a2145c3792984068b",
    "createdAt": 1288887922,
    "changedAt": 1011378306,
    "createdBy": "Collier Carney",
    "salesRepresentative": "labore",
    "customer": "aute",
    "value": 3268.03,
    "amountPaid": 3647.3,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "open"
  },
  {
    "id": "5d87793aad29626ea61f8ee1",
    "createdAt": 1357850315,
    "changedAt": 1118524564,
    "createdBy": "Rosalinda Tanner",
    "salesRepresentative": "ad",
    "customer": "fugiat",
    "value": 2390.54,
    "amountPaid": 3616.17,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "open"
  },
  {
    "id": "5d87793a38dfbd0f691ddbed",
    "createdAt": 1473937085,
    "changedAt": 861673539,
    "createdBy": "Louella Petty",
    "salesRepresentative": "minim",
    "customer": "nisi",
    "value": 1884.53,
    "amountPaid": 3666.22,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "cancelled"
  },
  {
    "id": "5d87793aec816c8ab431b6f4",
    "createdAt": 1221416027,
    "changedAt": 1426584029,
    "createdBy": "Walters Albert",
    "salesRepresentative": "anim",
    "customer": "ipsum",
    "value": 3443.1,
    "amountPaid": 1253.7,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "cancelled"
  },
  {
    "id": "5d87793a9a044c39e497d033",
    "createdAt": 1200740180,
    "changedAt": 1471034076,
    "createdBy": "Goodman Stanton",
    "salesRepresentative": "reprehenderit",
    "customer": "deserunt",
    "value": 1926.63,
    "amountPaid": 3289.75,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793a9d1b338a75ea9485",
    "createdAt": 1432274621,
    "changedAt": 1227250696,
    "createdBy": "Pansy Hughes",
    "salesRepresentative": "elit",
    "customer": "id",
    "value": 1513.32,
    "amountPaid": 3096.36,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "open"
  },
  {
    "id": "5d87793a57fb2301a54c51ea",
    "createdAt": 984686861,
    "changedAt": 989612004,
    "createdBy": "Erickson Morton",
    "salesRepresentative": "duis",
    "customer": "aliquip",
    "value": 3216.59,
    "amountPaid": 2582.6,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "open"
  },
  {
    "id": "5d87793ae071b2bd608e7381",
    "createdAt": 953106787,
    "changedAt": 839723957,
    "createdBy": "Rocha Hamilton",
    "salesRepresentative": "tempor",
    "customer": "proident",
    "value": 2785.11,
    "amountPaid": 1727.95,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793a19127f2b8d6a4bd9",
    "createdAt": 1094639795,
    "changedAt": 848166907,
    "createdBy": "Leach Adams",
    "salesRepresentative": "et",
    "customer": "voluptate",
    "value": 3934.99,
    "amountPaid": 2785.92,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793a70d034d30f91ca19",
    "createdAt": 1156394405,
    "changedAt": 1517380739,
    "createdBy": "Effie Ellison",
    "salesRepresentative": "ut",
    "customer": "in",
    "value": 3966.19,
    "amountPaid": 3529.04,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793a5cbc91abfc38f14b",
    "createdAt": 1546340592,
    "changedAt": 1307221152,
    "createdBy": "Margret Irwin",
    "salesRepresentative": "proident",
    "customer": "ad",
    "value": 1082.25,
    "amountPaid": 1523.76,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793a4ecd43de69df3c6b",
    "createdAt": 1092468425,
    "changedAt": 1084288162,
    "createdBy": "Erin Koch",
    "salesRepresentative": "amet",
    "customer": "minim",
    "value": 2634.3,
    "amountPaid": 2384.32,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793acd9ffbdd26617f82",
    "createdAt": 991710958,
    "changedAt": 860636114,
    "createdBy": "Miranda Dennis",
    "salesRepresentative": "in",
    "customer": "consequat",
    "value": 1813.48,
    "amountPaid": 3607.82,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "open"
  },
  {
    "id": "5d87793ab2eafb73372b2fa8",
    "createdAt": 1344897274,
    "changedAt": 1010071658,
    "createdBy": "Ashlee Duncan",
    "salesRepresentative": "eu",
    "customer": "est",
    "value": 3591.6,
    "amountPaid": 2647.33,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "cancelled"
  },
  {
    "id": "5d87793a7a497912b237ea69",
    "createdAt": 1509292246,
    "changedAt": 1000482637,
    "createdBy": "Nolan Watts",
    "salesRepresentative": "eiusmod",
    "customer": "culpa",
    "value": 3917.47,
    "amountPaid": 1797.43,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793a3fea3344e23af255",
    "createdAt": 803840637,
    "changedAt": 1165161960,
    "createdBy": "Jean Copeland",
    "salesRepresentative": "Lorem",
    "customer": "elit",
    "value": 3709.92,
    "amountPaid": 1965.1,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "open"
  },
  {
    "id": "5d87793af3bd27f1e3f9ec88",
    "createdAt": 1134935069,
    "changedAt": 901405634,
    "createdBy": "Leah Glenn",
    "salesRepresentative": "velit",
    "customer": "occaecat",
    "value": 1718.3,
    "amountPaid": 3753.39,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793ada896bc2b30b191c",
    "createdAt": 1425872935,
    "changedAt": 1011488862,
    "createdBy": "Jeanne Barrett",
    "salesRepresentative": "velit",
    "customer": "in",
    "value": 1326.86,
    "amountPaid": 1831.47,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793ad1be12adb93e2c93",
    "createdAt": 1387533155,
    "changedAt": 866302523,
    "createdBy": "Finley Glass",
    "salesRepresentative": "occaecat",
    "customer": "consectetur",
    "value": 2750.84,
    "amountPaid": 1136.78,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793a41624842f4186892",
    "createdAt": 865995191,
    "changedAt": 1369603001,
    "createdBy": "Wade Odom",
    "salesRepresentative": "ex",
    "customer": "veniam",
    "value": 1044.37,
    "amountPaid": 3417.53,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "cancelled"
  },
  {
    "id": "5d87793a7fd0a93bfc63f920",
    "createdAt": 1204826513,
    "changedAt": 1381579989,
    "createdBy": "Nita Stone",
    "salesRepresentative": "dolore",
    "customer": "adipisicing",
    "value": 3262.44,
    "amountPaid": 3671.61,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "open"
  },
  {
    "id": "5d87793a256be07fb789d3d6",
    "createdAt": 1106149426,
    "changedAt": 878263577,
    "createdBy": "Dona Acevedo",
    "salesRepresentative": "esse",
    "customer": "occaecat",
    "value": 2662.94,
    "amountPaid": 2916.12,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "cancelled"
  },
  {
    "id": "5d87793a01d90e35a5c0a4e1",
    "createdAt": 1064836334,
    "changedAt": 1149468126,
    "createdBy": "Foley Sosa",
    "salesRepresentative": "incididunt",
    "customer": "eiusmod",
    "value": 3583.32,
    "amountPaid": 2240.4,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "open"
  },
  {
    "id": "5d87793a46ffd72d794a5991",
    "createdAt": 843828554,
    "changedAt": 1379643535,
    "createdBy": "Blackburn Berry",
    "salesRepresentative": "id",
    "customer": "officia",
    "value": 1352.93,
    "amountPaid": 2541.3,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793a13667f43fd6705c0",
    "createdAt": 900356502,
    "changedAt": 1284661165,
    "createdBy": "Cortez Harding",
    "salesRepresentative": "quis",
    "customer": "nisi",
    "value": 3238.76,
    "amountPaid": 1701.55,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "open"
  },
  {
    "id": "5d87793a8e243ca25aff771c",
    "createdAt": 1300521535,
    "changedAt": 1357329039,
    "createdBy": "Janette Riddle",
    "salesRepresentative": "adipisicing",
    "customer": "nulla",
    "value": 1956.53,
    "amountPaid": 2759.69,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "cancelled"
  },
  {
    "id": "5d87793a9a5fb8bad6462f75",
    "createdAt": 1229440959,
    "changedAt": 1383424206,
    "createdBy": "Arnold Santana",
    "salesRepresentative": "dolore",
    "customer": "do",
    "value": 3544.16,
    "amountPaid": 3361.1,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793a0581d3dadbd000ee",
    "createdAt": 921950661,
    "changedAt": 1468126916,
    "createdBy": "Gilliam Pittman",
    "salesRepresentative": "ut",
    "customer": "nostrud",
    "value": 2683.79,
    "amountPaid": 2769.76,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "cancelled"
  },
  {
    "id": "5d87793a22dc032acde2741f",
    "createdAt": 1234084228,
    "changedAt": 1258952307,
    "createdBy": "Jarvis Pruitt",
    "salesRepresentative": "veniam",
    "customer": "voluptate",
    "value": 1011.29,
    "amountPaid": 3412.6,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "cancelled"
  },
  {
    "id": "5d87793a3df256d74a7fda74",
    "createdAt": 1147068207,
    "changedAt": 858886460,
    "createdBy": "Koch Brock",
    "salesRepresentative": "aliquip",
    "customer": "laborum",
    "value": 2296.89,
    "amountPaid": 1353.26,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "open"
  },
  {
    "id": "5d87793af55ea9b02c1dbc36",
    "createdAt": 1224339411,
    "changedAt": 1557976632,
    "createdBy": "Eloise Randolph",
    "salesRepresentative": "aute",
    "customer": "ea",
    "value": 2756.61,
    "amountPaid": 3899.95,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "cancelled"
  },
  {
    "id": "5d87793ab5b647763a99df55",
    "createdAt": 1035165481,
    "changedAt": 882559269,
    "createdBy": "Geraldine Santos",
    "salesRepresentative": "Lorem",
    "customer": "deserunt",
    "value": 3425.37,
    "amountPaid": 3928.87,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "cancelled"
  },
  {
    "id": "5d87793a19d52e9fa027a6b3",
    "createdAt": 1037593715,
    "changedAt": 1041632371,
    "createdBy": "Olivia Sutton",
    "salesRepresentative": "non",
    "customer": "fugiat",
    "value": 3710.26,
    "amountPaid": 1751.54,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793a70c1c8b8d4aa0e24",
    "createdAt": 1433421499,
    "changedAt": 844721354,
    "createdBy": "Vera Fernandez",
    "salesRepresentative": "cillum",
    "customer": "adipisicing",
    "value": 2983.87,
    "amountPaid": 1456.86,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793a465f74bd89212600",
    "createdAt": 1301086738,
    "changedAt": 1403822054,
    "createdBy": "Gwendolyn Elliott",
    "salesRepresentative": "velit",
    "customer": "reprehenderit",
    "value": 3806.23,
    "amountPaid": 2143.22,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793ad584fdf37d3a6513",
    "createdAt": 1440645671,
    "changedAt": 1368798385,
    "createdBy": "Jane Lawrence",
    "salesRepresentative": "esse",
    "customer": "ex",
    "value": 3400.91,
    "amountPaid": 1241.24,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "open"
  },
  {
    "id": "5d87793a1281a0e6c87f7681",
    "createdAt": 1067598742,
    "changedAt": 1064267343,
    "createdBy": "Holmes Norton",
    "salesRepresentative": "nisi",
    "customer": "voluptate",
    "value": 1432.8,
    "amountPaid": 1421.2,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793a87aef97eb201f991",
    "createdAt": 842148551,
    "changedAt": 1056540876,
    "createdBy": "Peterson Kinney",
    "salesRepresentative": "nisi",
    "customer": "mollit",
    "value": 3266.65,
    "amountPaid": 2601.04,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "open"
  },
  {
    "id": "5d87793ad5a0b8edd2a9e9f2",
    "createdAt": 837022449,
    "changedAt": 789386933,
    "createdBy": "Molina Wyatt",
    "salesRepresentative": "incididunt",
    "customer": "et",
    "value": 2533.52,
    "amountPaid": 2021.15,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "open"
  },
  {
    "id": "5d87793ab7d2b3bb076263ba",
    "createdAt": 841198522,
    "changedAt": 1553607351,
    "createdBy": "Celia Francis",
    "salesRepresentative": "ipsum",
    "customer": "dolor",
    "value": 3006.98,
    "amountPaid": 1654.1,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793a5820dadc21370cde",
    "createdAt": 959470538,
    "changedAt": 1303600066,
    "createdBy": "Chelsea Summers",
    "salesRepresentative": "irure",
    "customer": "aliquip",
    "value": 3126.33,
    "amountPaid": 3670.07,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "cancelled"
  },
  {
    "id": "5d87793a41113a4cca888791",
    "createdAt": 989545414,
    "changedAt": 922001727,
    "createdBy": "Alvarado Faulkner",
    "salesRepresentative": "eu",
    "customer": "occaecat",
    "value": 2141.87,
    "amountPaid": 2873.6,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "cancelled"
  },
  {
    "id": "5d87793a1a8d368f34c735d1",
    "createdAt": 1363031720,
    "changedAt": 1472542477,
    "createdBy": "Schroeder Guerra",
    "salesRepresentative": "laboris",
    "customer": "proident",
    "value": 2651.36,
    "amountPaid": 2276.7,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "open"
  },
  {
    "id": "5d87793a5d71c595e9669d52",
    "createdAt": 1001693889,
    "changedAt": 1180657384,
    "createdBy": "Hunter Graves",
    "salesRepresentative": "sit",
    "customer": "velit",
    "value": 2802.69,
    "amountPaid": 2528.72,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "cancelled"
  },
  {
    "id": "5d87793aeb1c505fd3d1d4b1",
    "createdAt": 1279687590,
    "changedAt": 1531409826,
    "createdBy": "Elsie Wells",
    "salesRepresentative": "adipisicing",
    "customer": "aute",
    "value": 1876.35,
    "amountPaid": 2161.33,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "open"
  },
  {
    "id": "5d87793a2a351a60c9562463",
    "createdAt": 1238880771,
    "changedAt": 1268000983,
    "createdBy": "Rosario Stark",
    "salesRepresentative": "do",
    "customer": "commodo",
    "value": 3437.51,
    "amountPaid": 3365.88,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "cancelled"
  },
  {
    "id": "5d87793a0aee3cf9823013a5",
    "createdAt": 977323842,
    "changedAt": 1213966339,
    "createdBy": "Ella Bonner",
    "salesRepresentative": "veniam",
    "customer": "ea",
    "value": 2572.36,
    "amountPaid": 3899.46,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793a16855055b795e642",
    "createdAt": 1302976942,
    "changedAt": 1165417575,
    "createdBy": "Knox Manning",
    "salesRepresentative": "elit",
    "customer": "ipsum",
    "value": 1330.87,
    "amountPaid": 2742.53,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "open"
  },
  {
    "id": "5d87793a335e369426d28deb",
    "createdAt": 848148714,
    "changedAt": 1509377979,
    "createdBy": "Shawn Jennings",
    "salesRepresentative": "exercitation",
    "customer": "laboris",
    "value": 1552.97,
    "amountPaid": 2226.89,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "cancelled"
  },
  {
    "id": "5d87793a3867a6a8bc369c47",
    "createdAt": 994381328,
    "changedAt": 1245572014,
    "createdBy": "Mccarty Kidd",
    "salesRepresentative": "aliquip",
    "customer": "adipisicing",
    "value": 3672.69,
    "amountPaid": 3512.52,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "cancelled"
  },
  {
    "id": "5d87793a430dc0b1e71e4401",
    "createdAt": 871625060,
    "changedAt": 1163534106,
    "createdBy": "Amelia Harrell",
    "salesRepresentative": "et",
    "customer": "eu",
    "value": 3054.13,
    "amountPaid": 2234.17,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "cancelled"
  },
  {
    "id": "5d87793a350995d0f57c10a3",
    "createdAt": 1138872348,
    "changedAt": 1520761648,
    "createdBy": "Bernadine Spencer",
    "salesRepresentative": "ea",
    "customer": "aliqua",
    "value": 2452.34,
    "amountPaid": 2525.71,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793ac354572fa9192606",
    "createdAt": 943133588,
    "changedAt": 849890123,
    "createdBy": "Stevenson Dodson",
    "salesRepresentative": "ex",
    "customer": "minim",
    "value": 3206.02,
    "amountPaid": 1729.61,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "cancelled"
  },
  {
    "id": "5d87793a9b6207a7bd66a255",
    "createdAt": 1063592835,
    "changedAt": 1345655681,
    "createdBy": "Ola Shaw",
    "salesRepresentative": "velit",
    "customer": "non",
    "value": 2502.88,
    "amountPaid": 2365.12,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793a8e8e167e96faf6f8",
    "createdAt": 1291420607,
    "changedAt": 1335851465,
    "createdBy": "Meyers Livingston",
    "salesRepresentative": "magna",
    "customer": "ea",
    "value": 3712.98,
    "amountPaid": 1162.49,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793a292a05f064c3d81e",
    "createdAt": 1152787598,
    "changedAt": 1534773853,
    "createdBy": "Hale Vincent",
    "salesRepresentative": "aliquip",
    "customer": "consequat",
    "value": 1720.11,
    "amountPaid": 2799.93,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793a2071d591d6bd4e85",
    "createdAt": 1478040742,
    "changedAt": 1499573716,
    "createdBy": "Higgins Spence",
    "salesRepresentative": "ipsum",
    "customer": "magna",
    "value": 3904.29,
    "amountPaid": 3985.15,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "cancelled"
  },
  {
    "id": "5d87793a411858a4a91e00fd",
    "createdAt": 1180013380,
    "changedAt": 1018561909,
    "createdBy": "Alston Valenzuela",
    "salesRepresentative": "dolor",
    "customer": "esse",
    "value": 1149.06,
    "amountPaid": 1321,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  },
  {
    "id": "5d87793a683db477b53b347b",
    "createdAt": 1326630048,
    "changedAt": 1537341517,
    "createdBy": "Stanley Allison",
    "salesRepresentative": "consequat",
    "customer": "reprehenderit",
    "value": 1487.48,
    "amountPaid": 3955.02,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "open"
  },
  {
    "id": "5d87793a55ad724b137be8ea",
    "createdAt": 1104172351,
    "changedAt": 1072007742,
    "createdBy": "Charlotte Salazar",
    "salesRepresentative": "occaecat",
    "customer": "proident",
    "value": 1776.25,
    "amountPaid": 1464.34,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "open"
  },
  {
    "id": "5d87793abbfa52495c666a93",
    "createdAt": 1500702167,
    "changedAt": 1193653860,
    "createdBy": "Knowles Hatfield",
    "salesRepresentative": "aliquip",
    "customer": "et",
    "value": 3595.16,
    "amountPaid": 1614.05,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "cancelled"
  },
  {
    "id": "5d87793a7e763fde5fbc9372",
    "createdAt": 1475742848,
    "changedAt": 1148373597,
    "createdBy": "Mcbride Valentine",
    "salesRepresentative": "magna",
    "customer": "tempor",
    "value": 3171.11,
    "amountPaid": 3072.66,
    "linkToPdf": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    "products": [],
    "status": "sent"
  }
]
