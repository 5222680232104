import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OrderCreatorOption, OrderCreatorOptionType } from 'src/app/models/order-creator-option.model';
import { OrderCreatorService } from 'src/app/services/order-creator.service';

@Component({
  selector: 'app-creator-step2',
  templateUrl: './creator-step2.component.html',
  styleUrls: ['./creator-step2.component.scss']
})
export class CreatorStep2Component implements OnInit, OnDestroy {
  loading: boolean = false;
  subscription: Subscription = new Subscription();

  selectedColor: OrderCreatorOption;
  color: OrderCreatorOption[];

  selectedFunctions: OrderCreatorOption[];
  functions: OrderCreatorOption[];

  selectedType: OrderCreatorOption;
  type: OrderCreatorOption[];

  selectedCoverType: OrderCreatorOption;
  coverType: OrderCreatorOption[];

  selectedPower: OrderCreatorOption;
  power: OrderCreatorOption[];

  selectedControl: OrderCreatorOption;
  control: OrderCreatorOption[];

  selectedCommunicationModule: OrderCreatorOption;
  communicationModule: OrderCreatorOption[];

  selectedAdditionalFilter: OrderCreatorOption;
  additionalFilter: OrderCreatorOption[];

  selectedExternalAggregate: OrderCreatorOption;
  externalAggregate: OrderCreatorOption[];

  constructor(
	private router: Router,
	private route: ActivatedRoute,
	private orderCreatorService: OrderCreatorService,
  ) { }

  ngOnInit(): void {
	this.preventEnterDirectlyByURL();
	this.getPossibleOptions();
	this.getSelectedOptions();
  }

  getPossibleOptions = (): void => {
	this.subscription.add(
	  this.orderCreatorService.orderCreatorState.subscribe(state => {
		  this.assignPossibleOptions(state.step2.possibleOptions);
	  })
	)
  }

  getSelectedOptions = (): void => {
	this.subscription.add(
	  this.orderCreatorService.orderCreatorState.subscribe(state => {
		if (state.step2.selectedOptions.length > 0) {
		  this.assignSelectedOptions(state.step2.selectedOptions);
		}
	  })
	)
  }

  handleNextStep = (): void => {
	// if (this.validateSelectedOptions()) {
		this.loading = true;
		this.saveSelectedOptions();
		this.getSummaryProducts();
	// }
  }

  validateSelectedOptions = (): boolean => {
	return (
	  this.selectedFunctions
	  && !!this.selectedType 
	  && !!this.selectedPower 
	  && !!this.selectedControl 
	  && !!this.selectedCommunicationModule 
	  && !!this.externalAggregate
	)
  }

  handlePrevStep = (): void => {
	this.router.navigate(['../step1'], { relativeTo: this.route })
  }

  getSummaryProducts = (): void => {
	const body = this.createReqBody();
	this.subscription.add(
	  this.orderCreatorService.processOrderCreatorStep2(body).subscribe(resp => {
		this.orderCreatorService.setOrderProducts(resp)
		this.loading = false;
		this.router.navigate(['../step3'], { relativeTo: this.route })
	  },
	  (err) => {
		this.loading = false;
		console.error(err);
	  })
	)
  }

  private preventEnterDirectlyByURL = (): void => {
	this.subscription.add(
	  this.orderCreatorService.orderCreatorState.subscribe(state => {
		if (state.step1.possibleOptions.length < 1 && state.step1.selectedOptions.length < 1) {
		  this.router.navigate(['../step1'], { relativeTo: this.route })
		}
	  })
	)
  }

  private assignPossibleOptions = (options: OrderCreatorOption[]): void => {
	this.color = options.filter(option => option.optionType === OrderCreatorOptionType.COLOR);
	this.functions = options.filter(option => option.optionType === OrderCreatorOptionType.FUNCTION);
	this.type = options.filter(option => option.optionType === OrderCreatorOptionType.TYPE);
	this.coverType = options.filter(option => option.optionType === OrderCreatorOptionType.COVER_TYPE);
	this.power = options.filter(option => option.optionType === OrderCreatorOptionType.POWER);
	this.control = options.filter(option => option.optionType === OrderCreatorOptionType.CONTROL);
	this.communicationModule = options.filter(option => option.optionType === OrderCreatorOptionType.COMMUNICATION_MODULE);
	this.additionalFilter = options.filter(option => option.optionType === OrderCreatorOptionType.ADDITIONAL_FILTER);
	this.externalAggregate = options.filter(option => option.optionType === OrderCreatorOptionType.EXTERNAL_AGGREGATE);
  }

  private assignSelectedOptions = (options: OrderCreatorOption[]): void => {
	const clearedOptions = options.filter(option => option !== undefined);

	this.selectedColor = clearedOptions.find(option => option.optionType === OrderCreatorOptionType.COLOR);
	this.selectedFunctions = clearedOptions.filter(option => option.optionType === OrderCreatorOptionType.FUNCTION);
	this.selectedType = clearedOptions.find(option => option.optionType === OrderCreatorOptionType.TYPE);
	this.selectedCoverType = clearedOptions.find(option => option.optionType === OrderCreatorOptionType.COVER_TYPE);
	this.selectedPower = clearedOptions.find(option => option.optionType === OrderCreatorOptionType.POWER);
	this.selectedControl = clearedOptions.find(option => option.optionType === OrderCreatorOptionType.CONTROL);
	this.selectedCommunicationModule = clearedOptions.find(option => option.optionType === OrderCreatorOptionType.COMMUNICATION_MODULE);
	this.selectedAdditionalFilter = clearedOptions.find(option => option.optionType === OrderCreatorOptionType.ADDITIONAL_FILTER);
	this.selectedExternalAggregate = clearedOptions.find(option => option.optionType === OrderCreatorOptionType.EXTERNAL_AGGREGATE);
  }

  private saveSelectedOptions = (): void => {
	this.orderCreatorService.setStep2SelectedOptions([
	  this.selectedColor,
	  ...this.selectedFunctions,
	  this.selectedType,
	  this.selectedCoverType,
	  this.selectedPower,
	  this.selectedControl,
	  this.selectedCommunicationModule,
	  this.selectedAdditionalFilter,
	  this.selectedExternalAggregate,
	])
  }

  private createReqBody = (): any => {
	return {
		color: this.selectedColor,
		functions: this.selectedFunctions,
		type: this.selectedType,
		coverType: this.selectedCoverType,
		power: this.selectedPower,
		control: this.selectedControl,
		communicationModule: this.selectedCommunicationModule,
		additionalFilter: this.selectedAdditionalFilter,
		externalAggregate: this.selectedExternalAggregate,
	}
  }

  ngOnDestroy(): void {
	  this.subscription.unsubscribe();
  }

}
