<div class="view-wrapper">
  <div class="product-container">
    <p-toast position="top-center" key="tc"></p-toast>
    <p-card [header]="product?.name">
      <div class="product-details">
        <div class="image-wrapper">
          <img
            *ngIf="product?.photo"
            class="product-image"
            [src]="product?.photo"
          />
        </div>
        <div class="product-info">
          <p>
            index: <strong>{{ product?.index }}</strong>
          </p>
          <p></p>
          <div class="p-grid">
            <div class="p-col-4">cena netto katalogowa:</div>
            <div class="p-col-3 p-text-right">
              {{ product?.producerNetPrice }} pln
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-4">grupa rabatowa:</div>
            <div class="p-col-3 p-text-right">{{ product?.discountGroup }}</div>
          </div>
          <div class="p-grid">
            <div class="p-col-4">cena netto po rabacie:</div>
            <div class="p-col-3 p-text-right">{{ product?.netPrice }} pln</div>
          </div>
          <div class="p-grid">
            <div class="p-col-4">stawka Vat:</div>
            <div class="p-col-3 p-text-right">{{ product?.vat }}</div>
          </div>
          <div class="p-grid">
            <div class="p-col-4">cena brutto:</div>
            <div class="p-col-3 p-text-right">{{ product?.grossPrice }} pln</div>
          </div>
          <div class="p-grid">
            <div class="p-col-4">stan magazynowy:</div>
            <div class="p-col-3 p-text-right">{{ product?.inStock }}</div>
          </div>
          <div class="p-grid">
            <div class="p-col-4">jednostka:</div>
            <div class="p-col-3 p-text-right">{{ product?.unit }}</div>
          </div>
          <!-- <div class="product-footer">
            <div class="links">
              <a [href]="">pdf</a>
              <span class="divider">|</span>
              <a [href]="product.siteUrl">www</a>
            </div>

            <span class="fill-empty"></span>

            <div class="add-to">
              <div class="quantity">
                <label for="quantityToAdd">Liczba:</label>
                <p-spinner [(ngModel)]="quantityToAdd" inputId="quantityToAdd" min="1"></p-spinner>
              </div>
              <div class="add-buttons">
                <button class="common">Dodaj do oferty</button>
                <button class="common">Dodaj do zamówienia</button>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </p-card>
    <p-card header="Opis:">
      <div class="product-description">
        <p>{{ product?.description }}</p>
      </div>  
    </p-card>
    <div>
      <button
        pButton
        type="button"
        class="p-my-3 p-mr-3"
        (click)="goBack()"
        label="Powrót"
      ></button>
      <button
        pButton
        type="button"
        class="p-my-3 p-button-success"
        (click)="addProductToCart(product)"
        label="Dodaj do zamówienia"
      ></button>
    </div>
  </div>
</div>
