<div class="view-wrapper">
  <div class="warehouse-container">
    <p-toast position="top-center" key="tc"></p-toast>
    <ng-template [ngIf]="!fetching" [ngIfElse]="spinner">
      <div class="table-container">
        <p-table #dt [columns]="cols" [value]="products" sortMode="multiple">
          <ng-template pTemplate="header" let-columns>
            <tr class="ig-table-tr">
              <th *ngFor="let col of columns" [class]="col.class" [pSortableColumn]="col.field" [width]="col.width">
                {{ col.header }}
                <ng-container *ngIf="col.field !== 'vat' && col.field !== 'addCart' && col.field !== 'icons'">
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </ng-container>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product let-columns="columns">
            <tr class="ig-table-tr">
              <ng-container *ngFor="let col of columns">
                <ng-container *ngIf="col.field === 'icons'">
                  <td class="icons-wrapper">
                    <ng-container *ngFor="let icon of getIcons(product)">
                      <img class="icon" [src]="icon" alt="icon" />
                    </ng-container>
                  </td>
                </ng-container>
                <ng-container *ngIf="col.field !== 'addCart' && col.field !== 'icons'">
                  <td class="{{col.class}} clickable p-text-nowrap p-text-truncate"
                    [routerLink]="[getProductRouterLink(product.index)]"
                    [queryParams]="getProductQueryParams(product.index)"
                  >
                    {{product[col.field]}}
                  </td>
                </ng-container>
                <ng-container *ngIf="col.field === 'addCart'">
                  <td class="button-container">
                      <button
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-shopping-cart"
                        class="p-button-rounded p-button-text p-button-info"
                        (click)="addProductToCart(product)"
                      ></button>
                  </td>
                </ng-container>
              </ng-container>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </ng-template>
    <ng-template #spinner>
      <div class="progressSpinner-container">
        <p-progressSpinner></p-progressSpinner>
      </div>
    </ng-template>
  </div>
</div>
