<div class="view-wrapper">
  <div class="warehouse-container">
    <p-toast position="top-center" key="tc"></p-toast>
    <p-panelMenu class="ig-submenu" [model]="categories"></p-panelMenu>
    <ng-template [ngIf]="!fetching" [ngIfElse]="spinner">
      <div class="table-container">
        <p-table #dt [columns]="cols" [value]="products" sortMode="multiple">
          <ng-template pTemplate="header" let-columns>
            <tr class="ig-table-tr">
              <th
                *ngFor="let col of columns"
                [class]="col.class"
                [pSortableColumn]="col.field"
                [width]="col.width"
              >
                {{ col.header }}
                <ng-container
                  *ngIf="col.field !== 'vat' && col.field !== 'addCart'"
                >
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </ng-container>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product let-columns="columns">
            <tr class="ig-table-tr">
              <ng-container *ngFor="let col of columns">
                <ng-container *ngIf="col.field !== 'addCart'">
                  <td
                    class="{{
                      col.class
                    }} clickable p-text-nowrap p-text-truncate"
                    [routerLink]="[getProductRouterLink(product.index)]"
                    [queryParams]="getProductQueryParams(product.index)"
                  >
                    {{ product[col.field] }}
                  </td>
                </ng-container>
                <ng-container *ngIf="col.field === 'addCart'">
                  <td class="button-container">
                    <button
                      pButton
                      pRipple
                      type="button"
                      icon="pi pi-shopping-cart"
                      class="p-button-rounded p-button-text p-button-info"
                      (click)="addProductToCart(product)"
                    ></button>
                    <!-- <ng-container *ngIf="isSalesIconVisible(product)">
                          <img [src]="getSalesIcon(product)" class="sales-icon" />
                        </ng-container> -->
                  </td>
                </ng-container>
              </ng-container>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </ng-template>
    <ng-template #spinner>
      <div class="progressSpinner-container">
        <p-progressSpinner></p-progressSpinner>
      </div>
    </ng-template>
  </div>
</div>
