import { environment } from "./../../../environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ProductsService } from "../../services/products.service";
import { CONSTS_ROUTES } from "../../consts";
// import { ProductsDataService } from "src/app/services/products-data.service";
import { MessageService } from "primeng/api";
import { CartService } from "src/app/services/cart.service";
import { Product } from "src/app/models/product.model";
import { ItemMenu } from "src/app/models/itemMenu.model";
import { Subscription } from "rxjs";

@Component({
  selector: "app-warehouse",
  templateUrl: "./warehouse.component.html",
  styleUrls: ["./warehouse.component.scss"],
  providers: [MessageService],
})
export class WarehouseComponent implements OnInit, OnDestroy {
  public products: Product[];
  public routes = CONSTS_ROUTES;
  public fetching = true;
  public selectedPage = 1;
  public categories: Array<ItemMenu>;
  public cols: any[];
  private categoryNo: number;
  private subscription: Subscription = new Subscription();

  constructor(
    private productsService: ProductsService,
    private activatedroute: ActivatedRoute,
    private messageService: MessageService,
    private cartService: CartService,
    private router: Router
  ) {}

  ngOnInit() {
    this.categoryNo =
      +Number(this.activatedroute.snapshot.queryParamMap.get("categoryNo")) ||
      1;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.getDefaultProductsForTable();
    this.initCols();
    this.getProducts();
  }

  initCols = () => {
    this.cols = [
      { field: "icons", header: "icons", width: "8%", class: "p-text-left" },
      {
        field: "producerIndex",
        header: "index",
        width: "12%",
        class: "p-text-left",
      },
      { field: "name", header: "nazwa", width: "30%", class: "p-text-left" },
      {
        field: "producerNetPrice",
        header: "cena katalogowa",
        width: "11%",
        class: "p-text-right",
      },
      {
        field: "discountGroup",
        header: "grupa rabatowa",
        width: "10%",
        class: "p-text-right",
      },
      {
        field: "netPrice",
        header: "cena netto",
        width: "8%",
        class: "p-text-right",
      },
      {
        field: "inStock",
        header: "magazyn",
        width: "8%",
        class: "p-text-right",
      },
      { field: "vat", header: "vat", width: "5%", class: "p-text-right" },
      { field: "addCart", header: "dodaj", width: "6%", class: "" },
    ];
  };

  getProducts() {
    this.subscription.add(
      this.productsService.productsForTable.subscribe((el) => {
        this.products = el.products;
        this.fetching = el.isFetching;
      })
    );
  }

  getIcons = (product: Product) => {
    if (product.groups.edges.length > 0) {
      const icons = product.groups.edges.map((edge) => {
        const photoUrl = environment.apiUrl + "assets/" + edge.node.photo;
        return photoUrl;
      });
      return icons;
    }
    return [];
  };

  private getDefaultProductsForTable = () => {
    this.productsService.getProductsFromCategory(this.categoryNo);
  };

  isSalesIconVisible = (product: Product): boolean => {
    if (product.groups) {
      const groupIndex = product.groups.edges.map((group) => group.node.index);
      return groupIndex[0] === 3;
    } else {
      return false;
    }
  };

  getSalesIcon = (product: Product): string => {
    const photo = product.groups.edges.map((group) => group.node.photo);
    return photo[0];
  };

  public getProductRouterLink = (index: string): string => {
    return `../${this.routes.PRODUCT.path}/${this.convertIndex(index)}`;
  };

  public getProductQueryParams = (index: string): { index: string } => {
    const params = {
      index: index,
    };
    return params;
  };

  public convertIndex(index) {
    const indexNew = index.replace("/", "_");
    return indexNew;
  }

  public async addProductToCart(product) {
    const msg = await this.cartService.addProductToCart(product);
    this.messageService.add(msg);
  }

  ngOnDestroy = () => {
    this.subscription.unsubscribe();
  };
}
