<div class="view-wrapper">
  <div class="supply-orders-container">
    <ng-template [ngIf]="!fetching" [ngIfElse]="spinner">
      <div class="table-container">
        <p-table [value]="supplyOrders">
          <ng-template pTemplate="header">
            <tr>
              <th width="8%" pSortableColumn="id">id</th>
              <th width="40%" [pSortableColumn]="'name'">klient</th>
              <th class="p-text-right" width="20%" pSortableColumn="totalPrice">
                wartość
                <p-sortIcon field="totalPrice"></p-sortIcon>
              </th>
              <th
                class="p-text-right"
                width="15%"
                [pSortableColumn]="'createdAt'"
              >
                data
                <p-sortIcon field="createdAt"></p-sortIcon>
              </th>
              <th class="p-text-right" width="17%" [pSortableColumn]="'status'">
                status
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-order>
            <tr
              class="clickable"
              [routerLink]="['../' + routes.SUPPLY_ORDER.path + '/' + order.id]"
            >
              <td>{{ order.id }}</td>
              <td>{{ order.name }}</td>
              <td class="p-text-right">{{ order.totalPrice | number }}</td>
              <td class="p-text-right">{{ order.createdAt }}</td>
              <td class="p-text-right">{{ order.status }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </ng-template>
    <ng-template #spinner>
      <div class="progressSpinner-container">
        <p-progressSpinner></p-progressSpinner>
      </div>
    </ng-template>
  </div>
</div>
