import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OrderCreatorOption, OrderCreatorOptionType, OrderCreatorPossibleOptionsResp } from 'src/app/models/order-creator-option.model';
import { OrderCreatorService } from 'src/app/services/order-creator.service';

@Component({
  selector: 'app-creator-step1',
  templateUrl: './creator-step1.component.html',
  styleUrls: ['./creator-step1.component.scss']
})
export class CreatorStep1Component implements OnInit, OnDestroy {
  loading: boolean = false;
  subscription: Subscription = new Subscription();

  selectedConfiguration: OrderCreatorOption;
  configuration: OrderCreatorOption[];

  selectedProducer: OrderCreatorOption;
  producer: OrderCreatorOption[];

  selectedAirConditionerType: OrderCreatorOption;
  airConditionerType: OrderCreatorOption[];

  constructor(
	private router: Router,
	private route: ActivatedRoute,
	private orderCreatorService: OrderCreatorService,
  ) { }

  ngOnInit(): void {
	this.getPossibleOptions();
	this.getSelectedOptions();
  }

  getPossibleOptions = (): void => {
	this.loading = true;
	this.subscription.add(
	  this.orderCreatorService.getOrderCreatorInitialData().subscribe((resp: OrderCreatorPossibleOptionsResp) => {
		this.orderCreatorService.setStep1PossibleOptions(
		  [
		    ...resp.configuration,
			...resp.producer,
			...resp.airConditionerType
		  ]
		);
		this.assignPossibleOptions(resp);
		this.loading = false;
	  },
	  (err) => {
		this.loading = false;
		console.error(err);
	  })
	)
  }

  getSelectedOptions = (): void => {
	this.subscription.add(
	  this.orderCreatorService.orderCreatorState.subscribe(data => {
		  if (data.step1.selectedOptions.length > 0) {
			this.assignSelectedOptions(data.step1.selectedOptions);
		  }
	  })
	)
  }

  handleNextStep = (): void => {
	if (this.selectedConfiguration && this.selectedAirConditionerType) {
	  this.saveSelectedOptions();
	  this.getStep2CreatorOptions();
	}
  }

  getStep2CreatorOptions = (): void => {
	this.loading = true;
	const body = this.createReqBody();
	this.subscription.add(
	  this.orderCreatorService.processOrderCreatorStep1(body).subscribe(resp => {
		this.orderCreatorService.setStep2PossibleOptions(
		  [
		    ...resp.color,
			...resp.function,
			...resp.type,
			...resp.coverType,
			...resp.power,
			...resp.control,
			...resp.communicationModule,
			...resp.additionalFilter,
			...resp.externalAggregate,
		  ]
		)
		this.loading = false;
		this.router.navigate(['../step2'], { relativeTo: this.route })
	  },
	  (err) => {
		this.loading = false;
		console.error(err);
	  })
	)
  }

  private assignPossibleOptions = (options: OrderCreatorPossibleOptionsResp): void => {
	this.configuration = options.configuration;
	this.producer = options.producer;
	this.airConditionerType = options.airConditionerType;
  }

  private assignSelectedOptions = (options: OrderCreatorOption[]): void => {
	const clearedOptions = options.filter(option => option !== undefined);

	this.selectedConfiguration = clearedOptions.find(option => option.optionType === OrderCreatorOptionType.CONFIGURATION);
	this.selectedProducer = clearedOptions.find(option => option.optionType === OrderCreatorOptionType.PRODUCER);
	this.selectedAirConditionerType = clearedOptions.find(option => option.optionType === OrderCreatorOptionType.AIR_CONDITIONER_TYPE);
  }

  private saveSelectedOptions = (): void => {
	this.orderCreatorService.setStep1SelectedOptions((
	  [
		this.selectedConfiguration,
		// this.selectedProducer,
		this.selectedAirConditionerType,
	  ]
	))
  }

  private createReqBody = (): any => {
	return {
	  configuration: this.selectedConfiguration,
	  airConditionerType: this.selectedAirConditionerType,
	}
  }

  ngOnDestroy(): void {
	this.subscription.unsubscribe();
  }

}
