import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OrderCreatorOption, OrderCreatorOptionType } from 'src/app/models/order-creator-option.model';
import { OrderCreatorService } from 'src/app/services/order-creator.service';

@Component({
  selector: 'app-creator-step3',
  templateUrl: './creator-step3.component.html',
  styleUrls: ['./creator-step3.component.scss']
})
export class CreatorStep3Component implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  orderProducts: any[];
  configuration: OrderCreatorOption;
  producer: OrderCreatorOption;
  airConditionerType: OrderCreatorOption;
  color: OrderCreatorOption;
  function: OrderCreatorOption[];
  type: OrderCreatorOption;
  coverType: OrderCreatorOption;
  power: OrderCreatorOption;
  control: OrderCreatorOption;
  communicationModule: OrderCreatorOption;
  additionalFilter: OrderCreatorOption;
  externalAggregate: OrderCreatorOption;

  constructor(
	private router: Router,
	private route: ActivatedRoute,
	private orderCreatorService: OrderCreatorService,
  ) { }

  ngOnInit(): void {
	this.preventEnterDirectlyByURL();
	this.getSelectedOptions();
	this.getOrderProducts();
  }

  getSelectedOptions = (): void => {
	this.subscription.add(
	  this.orderCreatorService.orderCreatorState.subscribe(state => {
		this.assignSelectedOptions([
			...state.step1.selectedOptions,
			...state.step2.selectedOptions,
		])
	  })
	)
  }

  getOrderProducts = (): void => {
	this.subscription.add(
	  this.orderCreatorService.orderCreatorState.subscribe(state => {
		this.orderProducts = state.step3.orderProducts;
	  })
	)
  }

  getFunctionsAsString = (): string => {
	return this.function.map(el => el.name).join(', ');
  }

  getFullPrice = (): number => {
	const allPrices = [];
	if (this.orderProducts.length > 0) {
	  this.orderProducts.forEach(product => allPrices.push(product.price));
	  return allPrices.reduce((a, b) => a + b);
	}
	return 0;
  }

  private preventEnterDirectlyByURL = (): void => {
	this.subscription.add(
	  this.orderCreatorService.orderCreatorState.subscribe(state => {
		if (state.step1.possibleOptions.length < 1 && state.step1.selectedOptions.length < 1) {
		  this.router.navigate(['../step1'], { relativeTo: this.route })
		}
	  })
	)
  }

  private assignSelectedOptions = (options: OrderCreatorOption[]): void => {
	const clearedOptions = options.filter(option => option !== undefined);

	this.configuration = clearedOptions.find(option => option.optionType === OrderCreatorOptionType.CONFIGURATION);
	this.producer = clearedOptions.find(option => option.optionType === OrderCreatorOptionType.PRODUCER);
	this.airConditionerType = clearedOptions.find(option => option.optionType === OrderCreatorOptionType.AIR_CONDITIONER_TYPE);
	this.color = clearedOptions.find(option => option.optionType === OrderCreatorOptionType.COLOR);
	this.function = clearedOptions.filter(option => option.optionType === OrderCreatorOptionType.FUNCTION);
	this.type = clearedOptions.find(option => option.optionType === OrderCreatorOptionType.TYPE);
	this.coverType = clearedOptions.find(option => option.optionType === OrderCreatorOptionType.COVER_TYPE);
	this.power = clearedOptions.find(option => option.optionType === OrderCreatorOptionType.POWER);
	this.control = clearedOptions.find(option => option.optionType === OrderCreatorOptionType.CONTROL);
	this.communicationModule = clearedOptions.find(option => option.optionType === OrderCreatorOptionType.COMMUNICATION_MODULE);
	this.additionalFilter = clearedOptions.find(option => option.optionType === OrderCreatorOptionType.ADDITIONAL_FILTER);
	this.externalAggregate = clearedOptions.find(option => option.optionType === OrderCreatorOptionType.EXTERNAL_AGGREGATE);
  }

  handleNextStep = (): void => {
	// add products to shopping cart
  }

  handlePrevStep = (): void => {
    this.router.navigate(['../step2'], { relativeTo: this.route })
  }

  ngOnDestroy(): void {
	this.subscription.unsubscribe();
  }
}
