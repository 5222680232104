import { Injectable } from "@angular/core";
// import { MessageService } from "primeng/api";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { ProductToOrdersType } from "src/generated/graphql";
const PRODUCTS_TO_ORDER = "products-to-order";
@Injectable({
  providedIn: "root",
})
export class CartService {
  private selectedProductsToOrders: ProductToOrdersType[] = [];

  constructor(
    // private messageService: MessageService,
    private localStorageService: LocalStorageService
  ) {}

  public setProductsToOrderInStorage(val) {
    this.localStorageService.set(PRODUCTS_TO_ORDER, val);
  }

  public async getProductsToOrderInStorage() {
    return await this.localStorageService.get(PRODUCTS_TO_ORDER);
  }

  public async addProductToCart(item) {
    // console.log("addProductToCart", item);
    const products: ProductToOrdersType[] = await this.getProductsToOrderInStorage();
    this.selectedProductsToOrders = products;
    // console.log("selectedProductsToOrders", this.selectedProductsToOrders);
    if (this.selectedProductsToOrders === null) {
      this.selectedProductsToOrders = [];
    }
    const indexOrders = this.selectedProductsToOrders.findIndex(
      (val) => val.id === item.id
    );

    // console.log("indexOrders", indexOrders);

    const msg = {
      key: "tc",
      severity: "success",
      summary: "Produkt",
      detail: "dodano do zamówienia",
    };

    if (indexOrders === -1) {
      item.qty = 1;
      item.additionalInfo = "";
      this.selectedProductsToOrders.push(item);
      // console.log(this.selectedProductsToOrders);

      msg.severity = "success";
      msg.summary = "Produkt";
      msg.detail = "dodano do zamówienia";

      this.setProductsToOrderInStorage(this.selectedProductsToOrders);
    } else {
      // console.log("juz jest");
      this.selectedProductsToOrders[indexOrders].qty += 1;

      msg.severity = "info";
      msg.summary = "Produkt";
      msg.detail = "zwiększono ilość w zamówieniu";

      this.setProductsToOrderInStorage(this.selectedProductsToOrders);
    }
    return msg;
  }
}
