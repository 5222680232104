import { Component } from "@angular/core";
import { CONSTS_ROUTES } from "../../consts";

@Component({
  selector: "app-dashboard-tiles",
  templateUrl: "./dashboard-tiles.component.html",
  styleUrls: ["./dashboard-tiles.component.scss"]
})
export class DashboardTilesComponent {
  routes = CONSTS_ROUTES;
}
