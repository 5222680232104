import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-order-creator',
  templateUrl: './order-creator.component.html',
  styleUrls: ['./order-creator.component.scss']
})
export class OrderCreatorComponent implements OnInit {
  items: MenuItem[];

  constructor() { }

  ngOnInit(): void {
	  this.items = [
		{
		//   label: '1',
		  routerLink: '',
	    },
		{
		//   label: '2',
		  routerLink: 'step2'
		},
		{
		//   label: '2',
		  routerLink: 'step3'
		}
	  ]
  }

}
