import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from './services/auth.service';
import {CONSTS_ROUTES} from './consts';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean  {
    // // TODO: Only for development
    // return true;
     if (!!this.auth.getToken()) {
       return true;
     } else {
       this.router.navigate(['/', CONSTS_ROUTES.LOGIN.path]);
       return false;
     }
  }
}
