import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { CONSTS_ROUTES } from "./consts";
import { AppComponent } from "./app.component";
import { OrderModal } from "./components/supply-orders/order";
import { LoginComponent } from "./components/login/login.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { DashboardTilesComponent } from "./components/dashboard-tiles/dashboard-tiles.component";
import { MyAccountComponent } from "./components/my-account/my-account.component";
import { EndCustomerOffersComponent } from "./components/end-customer-offers/end-customer-offers.component";
import { WarehouseComponent } from "./components/warehouse/warehouse.component";
import { SupplyOrderComponent } from "./components/supply-order/supply-order.component";
import { ProductComponent } from "./components/product/product.component";
import { SupplyOrdersComponent } from "./components/supply-orders/supply-orders.component";
import { GraphQLModule } from "./graphql.module";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EndCustomerOfferComponent } from "./components/end-customer-offer/end-customer-offer.component";
import { NewEndCustomerOfferComponent } from "./components/new-end-customer-offer/new-end-customer-offer.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthGuard } from "./auth.guard";
import { NewSupplyOrderComponent } from "./components/new-supply-order/new-supply-order.component";
import { SearchBarComponent } from "./components/search-bar/search-bar.component";
import { UrlNamePipe } from "./pipes/url-name.pipe";
import { SalesComponent } from "./components/sales/sales.component";
import { OrderCreatorComponent } from "./components/order-creator/order-creator.component";
import { CreatorStep1Component } from "./components/creator-step1/creator-step1.component";
import { CreatorStep2Component } from "./components/creator-step2/creator-step2.component";
import { CreatorStep3Component } from "./components/creator-step3/creator-step3.component";
import { EditFormComponent } from "./components/edit-form/edit-form.component";

// Angular Material
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";

// primeng
import { AutoCompleteModule } from "primeng/autocomplete";
import { TableModule } from "primeng/table";
import { ButtonModule } from "primeng/button";
import { SpinnerModule } from "primeng/spinner";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { ToastModule } from "primeng/toast";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { PanelMenuModule } from "primeng/panelmenu";
import { MenuModule } from "primeng/menu";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { CardModule } from "primeng/card";
import { PasswordModule } from "primeng/password";
import { InputTextModule } from "primeng/inputtext";
import { InputNumberModule } from "primeng/inputnumber";
import { InputTextareaModule } from "primeng/inputtextarea";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { MenubarModule } from "primeng/menubar";
import { DialogModule } from "primeng/dialog";
import { StepsModule } from "primeng/steps";
import { DropdownModule } from "primeng/dropdown";
import { MultiSelectModule } from "primeng/multiselect";

//TODO: Sort imports

export const appRoutes: Routes = [
  { path: CONSTS_ROUTES.EDIT_FORM.path, component: EditFormComponent },
  { path: CONSTS_ROUTES.LOGIN.path, component: LoginComponent },
  {
    path: CONSTS_ROUTES.DASHBOARD.path,
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: DashboardTilesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: CONSTS_ROUTES.MY_ACCOUNT.path,
        component: MyAccountComponent,
        canActivate: [AuthGuard],
      },
      {
        path: CONSTS_ROUTES.END_CUSTOMER_OFFERS.path,
        component: EndCustomerOffersComponent,
        canActivate: [AuthGuard],
      },
      {
        path: CONSTS_ROUTES.NEW_END_CUSTOMER_OFFER.path,
        component: NewEndCustomerOfferComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${CONSTS_ROUTES.END_CUSTOMER_OFFER.path}/:id`,
        component: EndCustomerOfferComponent,
        canActivate: [AuthGuard],
      },
      {
        path: CONSTS_ROUTES.WAREHOUSE.path,
        component: WarehouseComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${CONSTS_ROUTES.SUPPLY_ORDER.path}/:id`,
        component: SupplyOrderComponent,
        canActivate: [AuthGuard],
      },
      {
        path: CONSTS_ROUTES.SUPPLY_ORDERS.path,
        component: SupplyOrdersComponent,
        canActivate: [AuthGuard],
      },
      {
        path: CONSTS_ROUTES.ORDER_CREATOR.path,
        component: OrderCreatorComponent,
        canActivate: [AuthGuard],
        children: [
          { path: "", redirectTo: "step1", pathMatch: "full" },
          {
            path: CONSTS_ROUTES.ORDER_CREATOR_STEP_1.path,
            canActivate: [AuthGuard],
            component: CreatorStep1Component,
          },
          {
            path: CONSTS_ROUTES.ORDER_CREATOR_STEP_2.path,
            canActivate: [AuthGuard],
            component: CreatorStep2Component,
          },
          {
            path: CONSTS_ROUTES.ORDER_CREATOR_STEP_3.path,
            canActivate: [AuthGuard],
            component: CreatorStep3Component,
          },
        ],
      },
      {
        path: CONSTS_ROUTES.NEW_SUPPLY_ORDER.path,
        component: NewSupplyOrderComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${CONSTS_ROUTES.PRODUCT.path}/:id`,
        component: ProductComponent,
        canActivate: [AuthGuard],
      },
      {
        path: CONSTS_ROUTES.SALES.path,
        component: SalesComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "",
    redirectTo: "/dashboard",
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  { path: "**", redirectTo: "/dashboard", canActivate: [AuthGuard] },
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    DashboardTilesComponent,
    MyAccountComponent,
    EndCustomerOffersComponent,
    EndCustomerOfferComponent,
    NewEndCustomerOfferComponent,
    WarehouseComponent,
    SupplyOrderComponent,
    ProductComponent,
    SupplyOrdersComponent,
    NewSupplyOrderComponent,
    OrderModal,
    SearchBarComponent,
    UrlNamePipe,
    SalesComponent,
    OrderCreatorComponent,
    CreatorStep1Component,
    CreatorStep2Component,
    CreatorStep3Component,
    EditFormComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, { enableTracing: false }),
    GraphQLModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    TableModule,
    ButtonModule,
    SpinnerModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    DynamicDialogModule,
    MatInputModule,
    MatButtonModule,
    MatDividerModule,
    PanelMenuModule,
    MenuModule,
    CardModule,
    PasswordModule,
    InputTextModule,
    ProgressSpinnerModule,
    InputNumberModule,
    InputTextareaModule,
    MatFormFieldModule,
    ConfirmDialogModule,
    MenubarModule,
    DialogModule,
    StepsModule,
    DropdownModule,
    MatSelectModule,
    MultiSelectModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
