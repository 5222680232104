<div class="creator-step-wrapper">
	<p-card>
		<ng-template pTemplate="subtitle">
            Krok 2
        </ng-template>
		<ng-template pTemplate="content">
            <div class="p-fluid formgrid grid">
                <div class="field">
                    <p-dropdown [(ngModel)]="selectedColor" [options]="color" optionLabel="name" placeholder="Wybierz kolor"></p-dropdown>
                </div>
                <div class="field">
                    <p-multiSelect [(ngModel)]="selectedFunctions" [options]="functions" optionLabel="name" placeholder="Wybierz funkcje"></p-multiSelect>
                </div>
				<div class="field">
                    <p-dropdown [(ngModel)]="selectedType" [options]="type" optionLabel="name" placeholder="Wybierz typ"></p-dropdown>
                </div>
				<div class="field">
                    <p-dropdown [(ngModel)]="selectedCoverType" [options]="coverType" optionLabel="name" placeholder="Wybierz typ maskownicy"></p-dropdown>
                </div>
				<div class="field">
                    <p-dropdown [(ngModel)]="selectedPower" [options]="power" optionLabel="name" placeholder="Wybierz moc"></p-dropdown>
                </div>
				<div class="field">
                    <p-dropdown [(ngModel)]="selectedControl" [options]="control" optionLabel="name" placeholder="Wybierz sterowanie"></p-dropdown>
                </div>
				<div class="field">
                    <p-dropdown [(ngModel)]="selectedCommunicationModule" [options]="communicationModule" optionLabel="name" placeholder="Wybierz moduł komunikacji"></p-dropdown>
                </div>
				<div class="field">
                    <p-dropdown [(ngModel)]="selectedAdditionalFilter" [options]="additionalFilter" optionLabel="name" placeholder="Wybierz dodatkowy filtr"></p-dropdown>
                </div>
				<div class="field">
                    <p-dropdown [(ngModel)]="selectedExternalAggregate" [options]="externalAggregate" optionLabel="name" placeholder="Wybierz agregat zewnętrzny"></p-dropdown>
                </div>
            </div>
        </ng-template>
		<ng-template pTemplate="footer">
            <div class="btn-wrapper">
				<ng-container *ngIf="loading">
					<p-progressSpinner></p-progressSpinner>
				</ng-container>
                <p-button label="Wstecz" (onClick)="handlePrevStep()" icon="pi pi-angle-left" iconPos="left"></p-button>
                <p-button label="Dalej" (onClick)="handleNextStep()" icon="pi pi-angle-right" iconPos="right"></p-button>
            </div>
        </ng-template>
	</p-card>
</div>