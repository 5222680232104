<div class="supply-order-view">
  <h3>Zamówienie nr {{ supplyOrder?.id }}</h3>
  <!-- <mat-divider class="black"></mat-divider> -->
  <p class="p-my-3">Towar</p>
  <p-table class="p-my-3" [value]="supplyOrder?.orderProducts">
    <ng-template pTemplate="header">
      <tr>
        <th width="45%">nazwa</th>
        <th  class="p-text-right" width="20%">ilość</th>
        <th  class="p-text-right" width="35%">cena</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
      <tr>
        <td>{{ product.product.name }}</td>
        <td  class="p-text-right">{{ product.qty | number }}</td>
        <td  class="p-text-right">{{ product.product.netPrice | number }}</td>
      </tr>
    </ng-template>
  </p-table>
  <!-- <span class="fill-empty"></span> -->
  <p-button
    class="common add-product p-my-3"
    (onClick)="goBack()"
    label="Powrót"
  >
  </p-button>
</div>
