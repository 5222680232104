import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MessageService } from "primeng/api";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
  providers: [MessageService],
})
export class MyAccountComponent implements OnInit {
  public msgs = [];

  public changePasswordForm: FormGroup;

  public me: any;

  constructor(
    private messageService: MessageService,
    private userSerwice: UserService
  ) {
    this.changePasswordForm = new FormGroup({
      // oldPassword: new FormControl(),
      newPassword: new FormControl(),
      confirmNewPassword: new FormControl(),
    });
  }

  ngOnInit() {
    this.getMyAccount();
  }

  private async getMyAccount() {
    const data: any = await this.userSerwice.getMyAccount();
    console.log(data);

    if (data) {
      this.me = data;
    }
  }

  public async changePassword() {
    const newPassword = this.changePasswordForm.value.newPassword;
    const data: any = await this.userSerwice.changePassword(newPassword);
    console.log(data);

    if (data && data.isActive) {
      this.messageService.add({
        key: "cc",
        severity: "success",
        summary: "Zmiana hasła",
        detail: "zakończona powodzeniem",
      });
    } else {
      this.messageService.add({
        key: "cc",
        severity: "error",
        summary: "Zmiana hasła",
        detail: "nie powiodła się",
      });
    }
  }
}
