import { CONSTS_ROUTES } from './../consts';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public router: Router

  ) { }

  public getToken(): string {
    return localStorage.getItem('budwentToken');
  }

  public setToken(token: string) {
    localStorage.setItem('budwentToken', token);
  }

  public logout() {
    // console.log('logout');
    this.setToken('');
    this.router.navigate(['/', CONSTS_ROUTES.LOGIN.path]);

  }
}
