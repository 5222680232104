<div class="creator-step-wrapper">
	<p-card>
		<ng-template pTemplate="subtitle">
            Podsumowanie
        </ng-template>
		<ng-template pTemplate="content">
            <div class="lists-container">
				<ul class="order-summary">
					<li *ngIf="configuration">Układ: <span class="product-name">{{configuration.name}}</span></li>
					<li *ngIf="producer">Producent: <span class="product-name">{{producer.name}}</span></li>
					<li *ngIf="airConditionerType">Typ klimatyzatora: <span class="product-name">{{airConditionerType.name}}</span></li>
					<li *ngIf="color">Kolor: <span class="product-name">{{color.name}}</span></li>
					<li *ngIf="true">Funkcje: <span class="product-name">{{getFunctionsAsString()}}</span></li>
					<li *ngIf="type">Typ: <span class="product-name">{{type.name}}</span></li>
					<li *ngIf="coverType">Typ maskownicy: <span class="product-name">{{coverType.name}}</span></li>
					<li *ngIf="power">Moc: <span class="product-name">{{power.name}}</span></li>
					<li *ngIf="control">Sterowanie: <span class="product-name">{{control.name}}</span></li>
					<li *ngIf="communicationModule">Moduł komunikacji: <span class="product-name">{{communicationModule.name}}</span></li>
					<li *ngIf="additionalFilter">Dodatkowy filtr: <span class="product-name">{{additionalFilter.name}}</span></li>
					<li *ngIf="externalAggregate">Agregat zewnętrzny: <span class="product-name">{{externalAggregate.name}}</span></li>
				</ul>
				<ul class="order-summary products-list">
					<li *ngFor="let product of orderProducts">{{product.name}}</li>
				</ul>
            </div>
			<div class="price-wrapper">
				Cena całkowita produktów: <span class="price">{{getFullPrice()}}zł</span>
			</div>
        </ng-template>
		<ng-template pTemplate="footer">
            <div class="btn-wrapper">
                <p-button label="Wstecz" (onClick)="handlePrevStep()" icon="pi pi-angle-left" iconPos="left"></p-button>
                <p-button label="Dodaj produkty do koszyka" (onClick)="handleNextStep()" icon="pi pi-check" iconPos="right"></p-button>
            </div>
        </ng-template>
	</p-card>
</div>