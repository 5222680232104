<div class="dashboard-tiles-wrapper">
  <div class="row">
    <div
      class="tile clickable p-shadow-4"
      [routerLink]="[routes.NEW_SUPPLY_ORDER.path]"
    >
      Nowe zamówienie
    </div>
    <div
      class="tile clickable p-shadow-4"
      [routerLink]="[routes.SUPPLY_ORDERS.path]"
    >
      Zamówienia
    </div>
  </div>
  <div class="row">
    <div
      class="tile clickable p-shadow-4"
      [routerLink]="[routes.SALES.path]"
    >
      Wyprzedaż
    </div>
    <!-- <div class="tile box-none"></div> -->
    <div
      class="tile clickable p-shadow-4"
      [routerLink]="[routes.WAREHOUSE.path]"
    >
      Produkty
    </div>
  </div>
</div>
