<div class="login-wrapper box-centered p-shadow-4">
  <h1 class="title-login dark title-positioned">Edycja danych użytkownika</h1>
  <p class="dark">
    Użytkownik moze wysłać prośbę o u usunięcie konta, lub edycje danych.
  </p>

  <form [formGroup]="editForm">
    <div class="p-fluid p-grid">
      <div class="p-field p-col-12">
        <span class="p-float-label">
          <input
            type="text"
            id="inputtext"
            pInputText
            formControlName="email"
          />
          <label for="inputtext">Login</label>
        </span>
      </div>

      <div class="p-field p-col-12">
        <span class="p-float-label">
          <p-dropdown
            inputId="dropdown"
            formControlName="topic"
            [autoDisplayFirst]="false"
            [options]="[
              { name: 'usuń konto', value: 'delete' },
              { name: 'edytuj konto', value: 'edit' }
            ]"
            optionLabel="name"
          ></p-dropdown>
          <label for="dropdown">Temat wiadomości</label>
        </span>
      </div>

      <div class="p-field p-col-12">
        <span class="p-float-label">
          <textarea
            id="textgroup"
            [rows]="5"
            [cols]="30"
            formControlName="msg"
            pInputTextarea
            autoResize="autoResize"
          ></textarea>
          <label for="textgroup">Wiadomość</label>
        </span>
      </div>
    </div>
  </form>

  <button
    (click)="sendForm()"
    class="p-ml-2 p-button-primary"
    pButton
    type="button"
    label="Wyślij prośbę o edycje danych"
    [disabled]="!editForm.valid"
  ></button>

  <button
    pButton
    type="button"
    class="p-button-link"
    [routerLink]="['/']"
    label="Powrót"
  ></button>
  <!-- <p-messages [(value)]="msgs"></p-messages> -->

  <p-toast position="center" key="cc"></p-toast>
</div>
