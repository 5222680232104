<div class="row">
  <h1>Oferty dla klientow</h1>

  <table class="table table-striped">
    <thead>
    <tr>
      <th scope="col">id</th>
      <th scope="col">klient</th>
      <th scope="col">wartosc</th>
      <th scope="col">data</th>
      <th scope="col">status</th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
    </tr>
    </thead>
    <tbody>

    <tr *ngFor="let offer of offers" [routerLink]="['/', routes.END_CUSTOMER_OFFER.path, offer.id]">
      <td>{{offer.id}}</td>
      <td>{{offer.customer }}</td>
      <td>{{offer.value | number}}</td>
      <td>{{offer.createdAt | date: 'yyyy-MM-dd'}}</td>
      <td>{{offer.status}}</td>
      <td><a [href]="offer.linkToPdf" target="blank">zamowienie</a></td>
      <td><a [href]="offer.linkToPdf" target="blank">duplikat</a></td>
      <td><a [href]="offer.linkToPdf" target="blank">pdf</a></td>
    </tr>

    </tbody>
  </table>
</div>
