import { SupplyOrderService } from "./../../services/supply-order.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { switchMap, tap } from "rxjs/operators";
import { SupplyOrder } from "../../../../notes";
import { CONSTS_ROUTES } from "../../consts";
import { Subscription } from "rxjs";
import { Location } from "@angular/common";

@Component({
  selector: "app-supply-order",
  templateUrl: "./supply-order.component.html",
  styleUrls: ["./supply-order.component.scss"],
})
export class SupplyOrderComponent implements OnInit, OnDestroy {
  public supplyOrderId: string;
  public supplyOrder: SupplyOrder;
  public routes = CONSTS_ROUTES;
  subscription: Subscription;
  // public supplyOrders: any;

  constructor(
    private router: ActivatedRoute,
    private location: Location,
    private supplyOrderService: SupplyOrderService
  ) {}

  ngOnInit() {
    const supplyOrders$ = this.supplyOrderService.getSupplyOrders();

    const params$$ = this.router.paramMap.pipe(
      tap((params) => {
        this.supplyOrderId = params.get("id");
      }),
      switchMap((params) => supplyOrders$)
    );

    this.subscription = params$$.subscribe((supplyOrders) => {
      this.supplyOrder = supplyOrders.find(
        (order) => order.id.toString() === this.supplyOrderId
      );
      console.log(this.supplyOrder);
    });
  }

  public goBack() {
    this.location.back();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
