import { Component } from "@angular/core";
import { DynamicDialogRef, DynamicDialogConfig } from "primeng/dynamicdialog";

@Component({
  template: `
    <p-table
      [columns]="cols"
      [value]="products"
      [paginator]="true"
      [rows]="8"
      [responsive]="true"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{ col.header }}
            <p-sortIcon
              [field]="col.field"
              ariaLabel="Activate to sort"
              ariaLabelDesc="Activate to sort in descending order"
              ariaLabelAsc="Activate to sort in ascending order"
            >
            </p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">
            {{ rowData[col.field] }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  `
})
export class OrderModal {
  // cars: Car[];
  products = [];
  cols: any[];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit() {
    this.cols = [
      //   { field: "index", header: "index" },
      { field: "name", header: "nazwa" },
      { field: "qty", header: "ilość" },
      { field: "netPrice", header: "cena" }
    ];
    console.log(this.config);
    this.products = this.config.data.products;
    // this.carService.getCarsSmall().then(cars => this.cars = cars);
  }
}
