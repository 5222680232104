import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {EndCustomerOffer} from '../../../../notes';
import { endCustomerOffers } from '../../api/endCustomerOffers';

@Component({
  selector: 'app-end-customer-offer',
  templateUrl: './end-customer-offer.component.html',
  styleUrls: ['./end-customer-offer.component.scss']
})
export class EndCustomerOfferComponent implements OnInit {
  public offers: EndCustomerOffer[] = endCustomerOffers;
  public offer: EndCustomerOffer;

  constructor(private router: ActivatedRoute) { }

  ngOnInit() {
    this.router.paramMap.subscribe((params: ParamMap) => {
      this.offer = endCustomerOffers.find(offer => offer.id === params.get('id'))
    });
  }

}
