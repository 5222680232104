<div class="dashboard-wrapper">
  <p-menubar styleClass="menu-bar" [model]="menuItems">
    <ng-template pTemplate="start">
      <div class="panel-title">
        <a [routerLink]="['/']">Bud-Went B2B</a>
      </div>
    </ng-template>
    <ng-template pTemplate="end">
        
      <span class="control-icons">
        <!-- <i
          class="pi pi-user-edit pointer"
          [routerLink]="[routes.MY_ACCOUNT.path]"
        ></i> -->
        <!-- <i class="pi pi-arrow-circle-right pointer" (click)="logout()"></i> -->
        <!-- <p-button
          icon="pi pi-arrow-circle-right"
          class="p-button-rounded p-button-text"
          iconPos="left"
        ></p-button> -->
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-shopping-cart"
          class="p-button-rounded p-button-text p-button-info"
          [routerLink]="[routes.NEW_SUPPLY_ORDER.path]"
          badge="8"
          badgeClass="p-badge-danger"
        ></button>
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-user-edit"
          class="p-button-rounded p-button-text p-button-info"
          [routerLink]="[routes.MY_ACCOUNT.path]"
        ></button>
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-arrow-circle-right"
          class="p-button-rounded p-button-text p-button-info"
          (click)="logout()"
        ></button>
      </span>
    </ng-template>
  </p-menubar>
  <div class="main-wrapper">
    <div class="view-wrapper">
      <div class="top-bar">
        <span class="view-name">{{ routerLocation | urlName }}</span>
        <ng-container *ngIf="isCategoryNameVisible">
          <span class="view-name view-category">{{ categoryName }}</span>
        </ng-container>
        <span class="fill-empty"></span>
        <!-- <search-bar></search-bar> -->
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
