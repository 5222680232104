<div class="login-wrapper box-centered p-shadow-4">
  <div class="ig-logo" [routerLink]="['/']">
    <img src="./../../../assets/imgs/logo_bud-went.png" alt="" />
  </div>
  <h1 class="title-login dark title-positioned">Portal Bud-Went B2B</h1>

  <form (ngSubmit)="login()" [formGroup]="loginForm" *ngIf="!getToken()">
    <div class="p-fluid p-grid">
      <div class="p-field p-col-12">
        <span class="p-float-label">
          <input
            type="text"
            id="inputtext"
            pInputText
            formControlName="email"
          />
          <label for="inputtext">Login</label>
        </span>
      </div>

      <div class="p-field p-col-12">
        <span class="p-float-label">
          <input
            id="passgroup"
            type="password"
            pPassword
            formControlName="password"
          />
          <label for="passgroup">Hasło</label>
        </span>
      </div>
    </div>

    <button class="primary" mat-raised-button>Zaloguj</button>
  </form>

  <p-button
    (click)="goToEditForm()"
    label="Edytuj dane"
    styleClass="p-button-link"
  ></p-button>

  <div *ngIf="getToken()">
    <p>Jesteś zalogowany</p>
    <button
      pButton
      type="button"
      class="p-mr-2 p-button-danger"
      (click)="refreshToken()"
      label="Wyloguj"
    ></button>
    <button
      pButton
      type="button"
      class="p-ml-2 p-button-primary"
      [routerLink]="['/']"
      label="Powrót"
    ></button>
  </div>
  <!-- <p-messages [(value)]="msgs"></p-messages> -->
  <p-toast position="center" key="cc"></p-toast>
</div>
