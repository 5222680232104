import { Component } from "@angular/core";
import { CONSTS_ROUTES } from "./consts";
import { Router, NavigationEnd } from "@angular/router";

declare let gtag: Function;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  public routes = CONSTS_ROUTES;
  public msgs = [];
  constructor(public router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // console.log(event.urlAfterRedirects);
        gtag("config", "UA-163000472-1", {
          page_path: event.urlAfterRedirects
        });
      }
    });
  }
}
