import { Injectable } from "@angular/core";
import gql from "graphql-tag";
import { Apollo } from "apollo-angular";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  private productQuery = gql`
    query product($index: String!) {
      product(index: $index) {
        id
        index
        producerIndex
        name
        inStock
        unit
        netPrice
        grossPrice
        producerNetPrice
        vat
        discountGroup
        discountPercent
        updatedAt
        productGroup {
          id
        }
        groups {
          edges {
            node {
              index
              name
              photo
            }
          }
        }
        extras {
          product {
            id
            name
          }
          description
          imgUrl
          siteUrl
        }
        photo
        description
        imgUrl
        siteUrl
        isVirtual
      }
    }
  `;

  constructor(private apollo: Apollo) {}

  getProduct(id) {
    return this.apollo.query<any>({
      query: this.productQuery,
      variables: { index: encodeURI(id) },
    });
  }
}
