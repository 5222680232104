export const CONSTS_ROUTES = {
  EDIT_FORM: {
    path: "edit-form",
    name: "Edycja danych",
  },
  LOGIN: {
    path: "login",
    name: "",
  },
  DASHBOARD: {
    path: "dashboard",
    name: "Dashboard",
  },
  MY_ACCOUNT: {
    path: "my-account",
    name: "Moje konto",
  },
  END_CUSTOMER_OFFER: {
    path: "end-customer-offer",
    name: "",
  },
  NEW_END_CUSTOMER_OFFER: {
    path: "new-end-customer-offer",
    name: "",
  },
  END_CUSTOMER_OFFERS: {
    path: "end-customer-offers",
    name: "",
  },
  WAREHOUSE: {
    path: "warehouse",
    name: "Magazyn",
  },
  SUPPLY_ORDERS: {
    path: "supply-orders",
    name: "Zamówienia",
  },
  SUPPLY_ORDER: {
    path: "supply-order",
    name: "Zamówienie",
  },
  NEW_SUPPLY_ORDER: {
    path: "new-supply-order",
    name: "Nowe zamówienie",
  },
  ORDER_CREATOR: {
    path: "order-creator",
    name: "Kreator zamówienia",
  },
  ORDER_CREATOR_STEP_1: {
    path: "step1",
    name: "Kreator zamówienia - krok 1",
  },
  ORDER_CREATOR_STEP_2: {
    path: "step2",
    name: "Kreator zamówienia - krok 2",
  },
  ORDER_CREATOR_STEP_3: {
    path: "step3",
    name: "Kreator zamówienia - krok 3",
  },
  PRODUCT: {
    path: "product",
    name: "Produkt",
  },
  SALES: {
    path: "sales",
    name: "Wyprzedaż",
  },
};
