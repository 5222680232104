<div class="row">
  <div class="col-12">
    <h1>Nowa oferta</h1>
  </div>
  <div class="col-12">
    <h2>Klient</h2>
    <form [formGroup]="endCustomerOfferForm">
      <div class="form-group">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="isCompany" formControlName="isCompany">
            <label for="isCompany" class="form-check-label">Oferta dla firmy</label>
        </div>
      </div>
      <div class="form-row" [hidden]="!endCustomerOfferForm.get('isCompany').value">
        <div class="col">
          <label for="companyName">Nazwa firmy</label>
          <input type="text" class="form-control"
                 id="companyName"
                 formControlName="companyName"
                 placeholder="Nazwa firmy">
        </div>
        <div class="col">
          <label for="companyTaxNumber">NIP</label>
          <input type="text" class="form-control"
                 id="companyTaxNumber"
                 formControlName="companyTaxNumber"
                 placeholder="NIP">
        </div>
      </div>

      <div class="form-row">
        <div class="col">
          <label for="firstName">Imię</label>
          <input type="text" class="form-control" id="firstName" formControlName="firstName" placeholder="Imię">
        </div>
        <div class="col">
          <label for="lastName">Nazwisko</label>
          <input type="text" class="form-control" id="lastName" formControlName="lastName" placeholder="Nazwisko">
        </div>
      </div>

      <div class="form-row">
        <div class="col">
          <label for="mail">E-mail</label>
          <input type="text" class="form-control" id="mail" formControlName="mail" placeholder="e-mail">
        </div>
      </div>
    </form>
  </div>

  <div class="col-12">
    <h2>Produkty</h2>
    <div class="row justify-content-end">
      <div class="col-12">
        <p-autoComplete [(ngModel)]="selectedProducts"
                        [suggestions]="productSuggestions"
                        (completeMethod)="searchProducts($event)"
                        [multiple]="true"
                        [delay]="300"
                        [dataKey]="'index'"
                        [autoHighlight]="true"
                        placeholder="Wpisz nazwę bądź indeks produktu"
                        [inputStyleClass]="'full-width'">
          <ng-template let-suggestion pTemplate="item">
            <div>{{suggestion.index}}, {{suggestion.name}}</div>
          </ng-template>

          <ng-template let-value pTemplate="selectedItem">
            <div>
              <p>{{value.name}} ({{value.index}})</p>
            </div>
          </ng-template>
        </p-autoComplete>
        <ng-container *ngIf="selectedProducts.length">
          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col">zdjecie</th>
              <th scope="col">id</th>
              <th scope="col">nazwa</th>
              <th scope="col">cena katalogowa</th>
              <th scope="col">grupy rabatowe</th>
              <th scope="col">cena</th>
              <th scope="col">ilość</th>
              <th scope="col">stawka VAT</th>
              <th scope="col">usuń</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let selected of selectedProducts">
              <th><img class="img-fluid" [src]="selected.imgUrl ? selected.imgUrl : ''"></th>
              <th scope="row">{{selected.index}}</th>
              <td>{{selected.name }}</td>
              <td>{{selected.grossPrice| number }}</td>
              <td>{{selected.discountGroup}}</td>
              <td>{{selected.netPrice | number}}</td>
              <td>
                <div class="form-group mb-2">
                  <input type="number"
                         class="form-control"
                         [value]="selected.quantity || 1"
                         (change)="setQuantity('product', selected.index, $event)">
                </div>
              </td>
              <td>{{selected.vat}}</td>
              <td (click)="remove('product', selected.index)"><span>usuń</span></td>
            </tr>
            </tbody>
          </table>
        </ng-container>
        <p *ngIf="!selectedProducts.length">Nie dodano żadnych produktów</p>
      </div>
      <div class="col-12 col-md-6">
        <div>
          <table class="table table-striped">
            <tbody>
            <tr>
              <td scope="col">Suma netto</td>
              <td>{{getFinancialSummary().products.netSum | currency:'PLN'}}</td>
            </tr>
            <tr>
              <td scope="col">Suma VAT</td>
              <td>{{getFinancialSummary().products.vatSum | currency:'PLN'}}</td>
            </tr>
            <tr>
              <td scope="col">Suma brutto</td>
              <td>{{getFinancialSummary().products.grossSum | currency:'PLN'}}</td>
            </tr>
            <tr>
              <td scope="col">Zysk netto</td>
              <td>{{getFinancialSummary().products.netProfit | currency:'PLN'}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12">
    <h2>Usługi</h2>
    <div class="row justify-content-end">
      <div class="col-12">
        <form (ngSubmit)="addService()" [formGroup]="newServiceForm">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Nazwa i cena usługi</span>
            </div>
            <input type="text"
                   class="form-control"
                   id="newServiceName"
                   placeholder="Nazwa uslugi"
                   formControlName="newServiceName">
            <input type="number"
                   class="form-control"
                   id="newServiceNetPrice"
                   placeholder="Cena netto"
                   formControlName="newServiceNetPrice">
            <div class="input-group-append">
              <span class="input-group-text">PLN</span>
            </div>
            <div class="input-group-append">
              <select class="custom-select" formControlName="newServiceVATRate">
                <option *ngFor="let rate of VATRates" [value]="rate.rate">{{rate.name}}</option>
              </select>
            </div>
            <div class="input-group-append">
              <button class="btn btn-outline-primary text-white bg-primary" type="submit">Dodaj usługę</button>
            </div>
          </div>
        </form>


        <ng-container *ngIf="selectedServices.length">
          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col">nr</th>
              <th scope="col">nazwa</th>
              <th scope="col">cena netto</th>
              <th scope="col">stawka VAT</th>
              <th scope="col">cena brutto</th>
              <th scope="col">usuń</th>
            </tr>
            </thead>
            <tbody>

            <tr *ngFor="let selected of selectedServices; let i = index">
              <th scope="row">{{i}}</th>
              <td>{{selected.name }}</td>
              <td>{{selected.netPrice| number }}</td>
              <td>{{selected.vat}}</td>
              <td>{{selected.grossPrice | number}}</td>
              <td (click)="remove('service', selected.index)"><span>usuń</span></td>
            </tr>

            </tbody>
          </table>
        </ng-container>
        <p *ngIf="!selectedServices.length">Nie dodano żadnych usług</p>
      </div>
      <div class="col-12 col-md-6">
        <div>
          <table class="table table-striped">
            <tbody>
            <tr>
              <td scope="col">Suma netto</td>
              <td>{{getFinancialSummary().services.netSum | currency:'PLN' }}</td>
            </tr>
            <tr>
              <td scope="col">Suma VAT</td>
              <td>{{getFinancialSummary().services.vatSum | currency:'PLN'}}</td>
            </tr>
            <tr>
              <td scope="col">Suma brutto</td>
              <td>{{getFinancialSummary().services.grossSum | currency:'PLN'}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>

  <div class="col-12">
    <h2>Podsumowanie</h2>
    <div class="col-12">
      <div>
        <table class="table table-striped">
          <tbody>
          <tr>
            <td scope="col">Suma netto</td>
            <td>{{getFinancialSummary().products.netSum + getFinancialSummary().services.netSum  | currency:'PLN'}}</td>
          </tr>
          <tr>
            <td scope="col">Suma VAT</td>
            <td>{{getFinancialSummary().products.vatSum + getFinancialSummary().services.vatSum | currency:'PLN'}}</td>
          </tr>
          <tr>
            <td scope="col">Suma brutto</td>
            <td>{{getFinancialSummary().products.grossSum + getFinancialSummary().services.grossSum | currency:'PLN'}}</td>
          </tr>
          <tr>
            <td scope="col">Zysk netto</td>
            <td>{{getFinancialSummary().products.netProfit + getFinancialSummary().services.netProfit | currency:'PLN'}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <button type="button"
            class="btn btn-primary btn-lg btn-block" (click)="createEndCustomerOffer()">Stwórz ofertę
    </button>
  </div>

</div>
