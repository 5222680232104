import { environment } from "./../environments/environment";
import { NgModule } from "@angular/core";
import { ApolloModule, Apollo } from "apollo-angular";
import { HttpLinkModule, HttpLink } from "apollo-angular-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink } from "apollo-link";
import { AuthService } from "./services/auth.service";
import { onError } from "apollo-link-error";
import { MessageService } from "primeng/api";

const uri = environment.apiUrl + "graphql/"; // <-- add the URL of the GraphQL server prod
// export function createApollo(httpLink: HttpLink) {
//   return {
//     link: httpLink.create({uri, withCredentials: true}),
//     cache: new InMemoryCache(),
//   };
// }

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [MessageService],
})
export class GraphQLModule {
  constructor(
    apollo: Apollo,
    httpLink: HttpLink,
    private messageService: MessageService,
    private authService: AuthService
  ) {
    const authLink = new ApolloLink((operation, forward) => {
      const token = this.authService.getToken();
      operation.setContext({
        headers: {
          Authorization: token ? `JWT ${token}` : "",
        },
      });

      // Call the next link in the middleware chain.
      return forward(operation);
    });

    const link = authLink.concat(
      httpLink.create({ uri, withCredentials: true })
    );

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) => {
          // alert(message);
          this.messageService.add({
            key: "ee",
            severity: "error",
            summary: "Logowanie",
            detail: message,
          });
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        });
      }
      if (networkError) {
        // console.log(`[Network error]: ${networkError}`);
        console.error("[Network error]:", networkError);
        const error: any = networkError;

        if (error.status === 401) {
          this.authService.logout();
        }
      }
    });

    apollo.create({
      link: ApolloLink.from([errorLink, link]),
      cache: new InMemoryCache(),
      connectToDevTools: true,
      defaultOptions: {
        watchQuery: {
          errorPolicy: "all",
        },
      },
    });
  }
}

// @NgModule({
//   exports: [ApolloModule, HttpLinkModule],
//   providers: [
//     {
//       provide: APOLLO_OPTIONS,
//       useFactory: createApollo,
//       deps: [HttpLink],
//     },
//   ],
// })
// export class GraphQLModule {}
