import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { ProductType } from "../../generated/graphql";
import { ApolloQueryResult } from "apollo-client";
import { Product } from "../models/product.model";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class ProductsService {
  private fetchedProductsPages = [];
  private products;
  private products$ = new BehaviorSubject<ProductType[]>([]);

  private productsForTableSource: BehaviorSubject<any> = new BehaviorSubject({
    isFetching: true,
    products: [],
  });
  public productsForTable = this.productsForTableSource.asObservable();

  private currentCategoryNameSource: BehaviorSubject<string> =
    new BehaviorSubject("");
  public currentCategoryName = this.currentCategoryNameSource.asObservable();

  private productsQuery = gql`
    query getProducts($page: Int) {
      products(first: $page) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            index
            producerIndex
            name
            inStock
            unit
            netPrice
            grossPrice
            producerNetPrice
            vat
            discountGroup
            discountPercent
            updatedAt
            groups {
              edges {
                node {
                  index
                  name
                  photo
                }
              }
            }
            productGroup {
              id
            }
            extras {
              product {
                id
                name
              }
              description
              imgUrl
              siteUrl
            }
            description
            imgUrl
            siteUrl
          }
        }
      }
    }
  `;

  private productQuery = gql`
    query getProduct($productIndex: String!) {
      search(phrase: $productIndex) {
        id
        index
        producerIndex
        name
        inStock
        unit
        netPrice
        grossPrice
        producerNetPrice
        vat
        discountGroup
        discountPercent
        updatedAt
        groups {
          edges {
            node {
              index
              name
              photo
            }
          }
        }
        extras {
          product {
            id
            name
          }
          description
          imgUrl
          siteUrl
        }
        description
        imgUrl
        siteUrl
        isVirtual
      }
    }
  `;

  private categoryQuery = gql`
    query getCategory($index: Int!) {
      category(index: $index) {
        id
        index
        name
        products {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              index
              producerIndex
              name
              inStock
              unit
              netPrice
              grossPrice
              producerNetPrice
              vat
              discountGroup
              updatedAt
              groups {
                edges {
                  node {
                    index
                    name
                    photo
                  }
                }
              }
              productGroup {
                id
              }
              extras {
                product {
                  id
                  name
                }
                description
                imgUrl
                siteUrl
              }
              description
              imgUrl
              siteUrl
            }
          }
        }
        productGroups {
          edges {
            node {
              id
              index
              title
              photo
              products {
                pageInfo {
                  startCursor
                  endCursor
                  hasNextPage
                  hasPreviousPage
                }
                edges {
                  cursor
                  node {
                    id
                    index
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  private categoriesQuery = gql`
    query {
      categories {
        edges {
          node {
            id
            index
            name
            parent {
              id
              index
            }
            subcategories {
              edges {
                node {
                  id
                  index
                  name
                  parent {
                    id
                    index
                    name
                  }
                  subcategories {
                    edges {
                      node {
                        id
                        index
                        name
                        parent {
                          id
                          index
                          name
                        }
                        subcategories {
                          edges {
                            node {
                              id
                              index
                              name
                              parent {
                                id
                                index
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  private groupsQuery = gql`
    query {
      groups {
        edges {
          node {
            id
            name
            photo
            products {
              pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
              }
              edges {
                cursor
                node {
                  id
                  index
                  producerIndex
                  name
                  inStock
                  unit
                  netPrice
                  grossPrice
                  producerNetPrice
                  vat
                  discountGroup
                  updatedAt
                  extras {
                    product {
                      id
                      name
                    }
                    description
                    imgUrl
                    siteUrl
                  }
                  description
                  imgUrl
                  siteUrl
                }
              }
            }
          }
        }
      }
    }
  `;
  private groupQuery = gql`
    query getCategory($index: Int!) {
      group(index: $index) {
        id
        name
        photo
        products {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              index
              producerIndex
              name
              inStock
              unit
              netPrice
              grossPrice
              producerNetPrice
              vat
              discountGroup
              updatedAt
              extras {
                product {
                  id
                  name
                }
                description
                imgUrl
                siteUrl
              }
              description
              imgUrl
              siteUrl
            }
          }
        }
      }
    }
  `;
  private queryProductsSubscription: Subscription;

  constructor(
    private apollo: Apollo,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    // this.queryProductsSubscription = this.apollo.query<any>({
    //   query: this.productsQuery
    // })
    //   .subscribe((res) => {
    //     this.products = res.data.products;
    //     this.products$.next(res.data.products);
    //   });
  }

  getGroups() {
    return this.apollo.query({
      query: this.groupsQuery,
    });
  }

  getGroup(index) {
    return this.apollo.query({
      query: this.groupQuery,
      variables: { index },
    });
  }

  getCategories() {
    return this.apollo.query({
      query: this.categoriesQuery,
    });
  }

  getCategory(index) {
    return this.apollo.query({
      query: this.categoryQuery,
      variables: { index },
    });
  }

  getProducts(page: number = 10): any {
    return this.apollo.query({
      query: this.productsQuery,
      variables: { page },
    });
  }

  getProduct(index: string): Observable<ApolloQueryResult<any>> {
    return this.apollo.query({
      query: this.productQuery,
      variables: { productIndex: index },
    });
  }

  public setProducts = (products: Product[]) => {
    this.productsForTableSource.next({
      ...this.productsForTableSource.value,
      products: products,
    });
  };

  private setIsFetching = (isFetching: boolean) => {
    this.productsForTableSource.next({
      ...this.productsForTableSource.value,
      isFetching: isFetching,
    });
  };

  public getProductsFromCategory(categoryNo: any) {
    this.setIsFetching(true);

    this.router.navigateByUrl(`/dashboard/warehouse?categoryNo=${categoryNo}`);

    this.getCategory(categoryNo).subscribe((res: any) => {
      const products = res.data.category.products.edges.map(
        (product) => product.node
      );
      const categoryName = res.data.category.name;
      this.setCurrentCategoryName(categoryName);
      this.setProducts(products);
      this.setIsFetching(false);
    });
  }

  private setCurrentCategoryName = (name: string): void => {
    this.currentCategoryNameSource.next(name);
  };
}
