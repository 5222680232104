import { CONSTS_ROUTES } from "./../../consts";
import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { AuthService } from "../../services/auth.service";
import { MessageService } from "primeng/api";
import { error } from "console";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  providers: [MessageService],
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public token: string;
  public msgs = [];

  constructor(
    private messageService: MessageService,
    // private formBuilder: FormBuilder,
    private apollo: Apollo,
    private authService: AuthService,
    public router: Router
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl(),
      password: new FormControl(),
    });
  }

  ngOnInit() {
    // this.verifyToken();
  }

  public getToken() {
    const token = this.authService.getToken();

    if (token) {
      return true;
    } else {
      return false;
    }
  }

  public login() {
    this.apollo
      .mutate({
        mutation: gql`
          mutation AuthToken($email: String!, $password: String!) {
            authToken(email: $email, password: $password) {
              token
            }
          }
        `,
        variables: this.loginForm.value,
      })
      .subscribe(
        (res: any) => {
          // console.log(res);
          if (res.data.authToken) {
            this.messageService.add({
              key: "cc",
              severity: "success",
              summary: "Logowanie",
              detail: "zalogowany",
            });

            this.authService.setToken(res.data.authToken.token);
            this.router.navigate(["/", CONSTS_ROUTES.DASHBOARD.path]);
          } else {
            this.messageService.add({
              key: "cc",
              severity: "error",
              summary: "Logowanie",
              detail: "błędne logowanie",
            });
          }
        },
        (error) => {
          console.log("log error", error);
          // this.messageService.add({
          //   key: "cc",
          //   severity: "error",
          //   summary: "Logowanie",
          //   detail: "Błędny login, lub hasło",
          // });
        }
      );
  }

  private verifyToken() {
    this.apollo
      .mutate({
        mutation: gql`
          mutation VerifyToken($token: String!) {
            verifyToken(token: $token) {
              payload
            }
          }
        `,
        variables: { token: this.getToken() },
      })
      .subscribe((res: any) => {
        // console.log("verify token", res);
      });
  }

  public goToEditForm() {
    this.router.navigate(["/", CONSTS_ROUTES.EDIT_FORM.path]);
  }

  public refreshToken() {
    this.authService.logout();
  }
}
