import { Pipe, PipeTransform } from '@angular/core';
import { CONSTS_ROUTES } from '../consts';

@Pipe({name: 'urlName'})
export class UrlNamePipe implements PipeTransform {
  transform(url: string): string {
    const route = Object.keys(CONSTS_ROUTES)
      .map(key => CONSTS_ROUTES[key])
      .find(route => route.path === url);
    return route ? route.name : 'NoName';
  }
}
