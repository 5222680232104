<div class="creator-step-wrapper">
	<p-card>
		<ng-template pTemplate="subtitle">
            Krok 1
        </ng-template>
		<ng-template pTemplate="content">
            <div class="p-fluid formgrid grid">
                <div class="field">
                    <p-dropdown [(ngModel)]="selectedConfiguration" [options]="configuration" [disabled]="loading" optionLabel="name" placeholder="Wybierz układ"></p-dropdown>
                </div>
                <div class="field">
                    <p-dropdown [(ngModel)]="selectedProducer" [options]="producer" [disabled]="true" optionLabel="name" placeholder="Wybierz producenta"></p-dropdown>
                </div>
				<div class="field">
                    <p-dropdown [(ngModel)]="selectedAirConditionerType" [options]="airConditionerType" [disabled]="loading" optionLabel="name" placeholder="Wybierz typ"></p-dropdown>
                </div>
            </div>
        </ng-template>
		<ng-template pTemplate="footer">
            <div class="btn-wrapper">
				<ng-container *ngIf="loading">
					<p-progressSpinner></p-progressSpinner>
				</ng-container>
                <p-button label="Dalej" (onClick)="handleNextStep()" icon="pi pi-angle-right" iconPos="right"></p-button>
            </div>
        </ng-template>
	</p-card>
</div>