import { OrderModal } from "./order";
import { SupplyOrderService } from "./../../services/supply-order.service";
import { Component, OnInit } from "@angular/core";
import { CONSTS_ROUTES } from "../../consts";
import { DialogService } from "primeng/dynamicdialog";

@Component({
  selector: "app-supply-orders",
  templateUrl: "./supply-orders.component.html",
  styleUrls: ["./supply-orders.component.scss"],
  providers: [DialogService],
})
export class SupplyOrdersComponent implements OnInit {
  public supplyOrders: any;
  public selectedOrder: any;
  public routes = CONSTS_ROUTES;
  public fetching = true;
  cols: any[];

  constructor(
    private supplyOrdersService: SupplyOrderService,
    public dialogService: DialogService
  ) {}

  ngOnInit() {
    this.cols = [
      { field: "id", header: "id" },
      // { field: 'name', header: 'klient' },
      { field: "totalPrice", header: "wartość" },
      { field: "createdAt", header: "data" },
      { field: "status", header: "status" },
    ];

    // console.log("==== pobieram zamowienia");
    this.getOrders();
  }

  private getOrders() {
    this.fetching = true;
    this.supplyOrdersService.getOrders().subscribe((supplyOrders: any) => {
      supplyOrders.map(el => {
        el.createdAt = this.formatDate(el.createdAt);
      })

      this.supplyOrders = supplyOrders;
      this.fetching = false;
    });
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const monthDateType = date.getMonth()+1;
    const dayDateType = date.getDate();

    let month = '';
    let day = '';
    
    if (dayDateType < 10) {
      day = '0' + dayDateType;
    } else {
      day = dayDateType.toLocaleString();
    }

    if (monthDateType < 10) {
      month = '0' + monthDateType;
    } else {
      month = monthDateType.toLocaleString();
    }

    return `${year}-${month}-${day}`;
  }

  onRowSelect(event) {
    // console.log(event);
    const products = event.data.products;
    const order: any = {};
    order.id = event.data.id;
    order.totalPrice = event.data.totalPrice;
    order.createdAt = event.data.createdAt;
    order.status = event.data.status;

    const ref = this.dialogService.open(OrderModal, {
      data: {
        id: order.id,
        products,
      },
      header: "Zamówienie nr " + order.id,
      width: "90%",
      dismissableMask: true,
    });
  }
}
