import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { CONSTS_ROUTES } from "src/app/consts";
import { ItemMenu } from "src/app/models/itemMenu.model";
import { Product } from "src/app/models/product.model";
import { CartService } from "src/app/services/cart.service";
import { ProductsService } from "src/app/services/products.service";

@Component({
  selector: "app-sales",
  templateUrl: "./sales.component.html",
  styleUrls: ["./sales.component.scss"],
  providers: [MessageService],
})
export class SalesComponent implements OnInit {
  public fetching = false;
  public categories: Array<ItemMenu>;
  public routes = CONSTS_ROUTES;
  public products: Product[];
  public cols: any[];
  public selectedPage = 1;
  private categoryNo: number;

  constructor(
    private activatedroute: ActivatedRoute,
    private messageService: MessageService,
    private cartService: CartService,
    private productsService: ProductsService
  ) {}

  ngOnInit(): void {
    this.categoryNo =
      +Number(this.activatedroute.snapshot.queryParamMap.get("categoryNo")) ||
      3;

    this.initCols();
    this.getGroup(3);
  }

  initCols = () => {
    this.cols = [
      {
        field: "producerIndex",
        header: "index",
        width: "12%",
        class: "p-text-left",
      },
      { field: "name", header: "nazwa", width: "30%", class: "p-text-left" },
      {
        field: "producerNetPrice",
        header: "cena katalogowa",
        width: "11%",
        class: "p-text-right",
      },
      {
        field: "discountGroup",
        header: "grupa rabatowa",
        width: "10%",
        class: "p-text-right",
      },
      {
        field: "netPrice",
        header: "cena netto",
        width: "8%",
        class: "p-text-right",
      },
      {
        field: "inStock",
        header: "magazyn",
        width: "8%",
        class: "p-text-right",
      },
      { field: "vat", header: "vat", width: "5%", class: "p-text-right" },
      { field: "addCart", header: "dodaj", width: "6%", class: "" },
    ];
  }

  public getProductRouterLink = (index: string): string => {
    return `../${this.routes.PRODUCT.path}/${index}`;
  };

  public getProductQueryParams = (index: string): { index: string } => {
    const params = {
      index: index,
    };
    return params;
  };

  public convertIndex(index) {
    const indexNew = index.replace("/", "_");
    return indexNew;
  }

  getGroups() {
    this.fetching = true;
    // this.selectedPage = page;
    this.productsService.getGroups().subscribe((res: any) => {
      this.fetching = false;
      console.log(res);

      this.products = res.data.groups.edges.map((product) => product.node);
    });
  }
  
  getGroup(index) {
    this.fetching = true;
    // this.selectedPage = page;
    this.productsService.getGroup(index).subscribe((res: any) => {
      this.fetching = false;
      console.log(res);

      this.products = res.data.group.products.edges.map(
        (product) => product.node
      );
    });
  }

  public async addProductToCart(product) {
    const msg = await this.cartService.addProductToCart(product);
    this.messageService.add(msg);
  }
}
