import { OrderCreatorOptionType } from "../models/order-creator-option.model";

export const step1AirConditionerTypeMock = [
	{
		name: 'ŚCIENNY',
		optionType: OrderCreatorOptionType.AIR_CONDITIONER_TYPE
	},
	{
		name: 'KASETONOWY',
		optionType: OrderCreatorOptionType.AIR_CONDITIONER_TYPE
	},
	{
		name: 'KANAŁOWY',
		optionType: OrderCreatorOptionType.AIR_CONDITIONER_TYPE
	},
	{
		name: 'PRZYPODŁOGOWY',
		optionType: OrderCreatorOptionType.AIR_CONDITIONER_TYPE
	},
	{
		name: 'PODSTROPOWY',
		optionType: OrderCreatorOptionType.AIR_CONDITIONER_TYPE
	},
]

export const step1ProducerMock = [
	{
		name: 'TOSHIBA',
		optionType: OrderCreatorOptionType.PRODUCER
	},
	{
		name: 'MITSUBISHI',
		optionType: OrderCreatorOptionType.PRODUCER
	},
	{
		name: 'DAIKIN',
		optionType: OrderCreatorOptionType.PRODUCER
	}
]

export const step1ConfigurationMock = [
  {
	name: 'SPLIT',
	optionType: OrderCreatorOptionType.CONFIGURATION
  },
  {
	name: 'MULTISPLIT',
	optionType: OrderCreatorOptionType.CONFIGURATION
  }
]

export const step2ColorMock = [
  {
	name: 'Biały',
	optionType: OrderCreatorOptionType.COLOR
  },
  {
	name: 'Czarny',
	optionType: OrderCreatorOptionType.COLOR
  },
  {
	name: 'Srebrny',
	optionType: OrderCreatorOptionType.COLOR
  }
]

export const step2FunctionsMock = [
  {
	name: 'I-see',
	optionType: OrderCreatorOptionType.FUNCTION
  },
  {
	name: 'Plasma quad',
	optionType: OrderCreatorOptionType.FUNCTION
  },
  {
	name: 'Filtr zapachowy',
	optionType: OrderCreatorOptionType.FUNCTION
  }
]

export const step2TypeMock = [
  {
	name: 'AP',
	optionType: OrderCreatorOptionType.TYPE
  },
  {
	name: 'EF',
	optionType: OrderCreatorOptionType.TYPE
  },
  {
	name: 'LN',
	optionType: OrderCreatorOptionType.TYPE
  }
]

export const step2CoverTypeMock = [
	{
	  name: 'Maskownica z pilotem bezprzewodowym',
	  optionType: OrderCreatorOptionType.COVER_TYPE
	},
	{
	  name: 'Maskownica do pilota przewodowego',
	  optionType: OrderCreatorOptionType.COVER_TYPE
	},
]

export const step2PowerMock = [
  {
	name: '2',
	optionType: OrderCreatorOptionType.POWER
  },
  {
	name: '3',
	optionType: OrderCreatorOptionType.POWER
  },
  {
	name: '4',
	optionType: OrderCreatorOptionType.POWER
  }
]

export const step2ControlMock = [
  {
	name: 'Bezprzewodowy',
	optionType: OrderCreatorOptionType.CONTROL
  },
  {
	name: 'Przewodowy',
	optionType: OrderCreatorOptionType.CONTROL
  },
]

export const step2CommunicationModuleMock = [
  {
	name: 'WI-FI',
	optionType: OrderCreatorOptionType.COMMUNICATION_MODULE
  },
  {
	name: 'KNX',
	optionType: OrderCreatorOptionType.COMMUNICATION_MODULE
  },
  {
	name: 'LON WORKS',
	optionType: OrderCreatorOptionType.COMMUNICATION_MODULE
  }
]

export const step2AdditionalFilterMock = [
  {
	name: 'Filtr z jonami srebra',
	optionType: OrderCreatorOptionType.ADDITIONAL_FILTER
  },
  {
	name: 'Plasma quad',
	optionType: OrderCreatorOptionType.ADDITIONAL_FILTER
  },
]

export const step2ExternalAggregateMock = [
  {
	name: 'Standard',
	optionType: OrderCreatorOptionType.EXTERNAL_AGGREGATE
  },
  {
	name: 'Hyper heating',
	optionType: OrderCreatorOptionType.EXTERNAL_AGGREGATE
  },
]

export const step3ProductsMock = [
	{
		id: 'ncf8249b4g11f1ff',
		name: 'Nazwa produktu 1',
		price: 199
	},
	{
		id: 'fa4efa343f4gg4g',
		name: 'Nazwa produktu 2',
		price: 199
	},
	{
		id: 'sg543df3123f',
		name: 'Nazwa produktu 3',
		price: 199
	}
]