import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ProductToOrdersType, ProductType } from "../../../generated/graphql";
import { ProductService } from "../../services/product.service";
import { Location } from "@angular/common";
import { CartService } from "src/app/services/cart.service";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.scss"],
  providers: [MessageService],
})
export class ProductComponent implements OnInit {
  public product: ProductType;
  quantityToAdd: number = 1;

  public selectedProductsToOrders: ProductToOrdersType[] = [];

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private messageService: MessageService,
    private cartService: CartService,
    private productService: ProductService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      // console.log("params", params);
      const index = params["index"];
      this.productService.getProduct(index).subscribe((res) => {
        this.product = res.data.product;
        // console.log(res);
      });
    });
    this.setproductsFromStorage();
  }

  private async setproductsFromStorage() {
    const productsToOrder = await this.cartService.getProductsToOrderInStorage();
    // console.log("productsToOrder", productsToOrder);
    if (productsToOrder) {
      this.selectedProductsToOrders = productsToOrder;
    }
  }

  public async addProductToCart(item) {
    // console.log(item);

    const msg = await this.cartService.addProductToCart(item);
    this.messageService.add(msg);
  }

  public goBack() {
    this.location.back();
  }
}
