import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { Subscription } from "rxjs";
import { MenuItem } from "primeng/api";
import { CONSTS_ROUTES } from "../../consts";
import { AuthService } from "src/app/services/auth.service";
import { ProductsService } from "src/app/services/products.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public routes = CONSTS_ROUTES;
  menuItems: MenuItem[];
  routerLocation: string;
  subscription: Subscription = new Subscription();
  categoryNo: any;
  categories: any = [];
  isFetching: boolean = true;
  categoryName: string;
  isCategoryNameVisible: boolean = false;

  constructor(
    private router: Router,
    private location: Location,
    private authService: AuthService,
    private productsService: ProductsService,
  ) {
    this.router.events.subscribe((val) => {
      const urlPath = this.location.path().split("/");
      this.routerLocation = this.getRouterLocation(urlPath);
      this.setIsCategoryNameVisible()
    });
  }

  ngOnInit() {
    this.getCategories();
    this.getCurrentCategoryName();
    this.initMenuItems();
  }

  private initMenuItems = () => {
    this.menuItems = [
      {
        label: "Dashboard",
        icon: "pi pi-folder",
        routerLink: "/",
      },
      {
        label: "Zamówienia",
        icon: "pi pi-pw pi-file",
        routerLink: "supply-orders",
      },
	  // {
		// label: "Kreator zamówienia",
		// icon: "pi pi-pencil",
		// routerLink: "order-creator"
	  // },
      {
        label: "Nowe zamówienie",
        icon: "pi pi-folder",
        routerLink: "new-supply-order",
      },
      {
        label: "Produkty",
        icon: "pi pi-folder",
        items: [],
        disabled: this.isFetching,
      },
      {
        label: "Wyprzedaż",
        icon: "pi pi-folder",
        routerLink: "sales",
      },
    ];
  }

  private getCategories() {
    this.subscription.add(
      this.productsService.getCategories().subscribe((res: any) => {
        const categoriesTmp = res.data.categories.edges;
  
        categoriesTmp.forEach((element) => {
          if (element.node.parent === null && element.node.index > 2) {
            this.setCategories(element.node, this.categories);
          }
        });

        this.isFetching = false;
        this.activateProductsMenuButton();
      })
    )
  }

  private getCurrentCategoryName = (): void => {
    this.subscription.add(
      this.productsService.currentCategoryName.subscribe(name => this.categoryName = name)
    )
  }

  private setCategories(data, array) {
    const item: MenuItem = {
      label: String(data.name),
      id: String(data.index),
    };

    const subcategories = data.subcategories.edges;

    if (subcategories.length > 0) {
      item.items = [];
      subcategories.forEach((element) => {
        this.setCategories(element.node, item.items);
      });
    } else {
      item.command = () => {
        const categoryTmp = item.id;
        this.categoryNo = categoryTmp;
        this.productsService.getProductsFromCategory(this.categoryNo);
      };
    }

    array.push(item);
    this.addCategoriesToMenu();
  }

  public setIsCategoryNameVisible = (): void => {
    this.isCategoryNameVisible = this.routerLocation === 'warehouse'
  }

  private activateProductsMenuButton = () => {
    const productsMenuButtonIdx = this.menuItems.findIndex(item => item.label === "Produkty");
    this.menuItems[productsMenuButtonIdx].disabled = this.isFetching;
  }

  private addCategoriesToMenu = () => {
    const productsMenuButtonIndex = this.menuItems.findIndex(
      (el) => el.label === "Produkty"
    );

    this.menuItems[productsMenuButtonIndex].items = [];
    
    this.categories.forEach(el => {
      this.menuItems[productsMenuButtonIndex].items.push(el);
    })
  };

  private getRouterLocation = (path: string[]): string => {
    const name = this.getPageName(path);

    if (name.includes("?")) {
      return name.substring(0, name.indexOf("?"));
    } else {
      return name;
    }
  };

  private getPageName = (path: string[]): string => {
    const isProduct = path.find(str => str === 'product');
    return isProduct ? isProduct : path[path.length - 1];
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy = () => {
    this.subscription.unsubscribe();
  }
}
