import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private meQuery = gql`
    query {
      me {
        company {
          id
          mainUser {
            email
            firstName
            lastName
          }
          name
          nip
          traderId
          salesperson {
            id
            firstName
            lastName
          }
          globalLimit
          currentLimit
          isBlocked
        }
      }
    }
  `;

  private changePasswordQuery = gql`
    mutation Mutation($newPassword: String!) {
      changePassword(newPassword: $newPassword) {
        email
        isActive
      }
    }
  `;

  constructor(private apollo: Apollo) {}

  public getMyAccount() {
    const response = this.apollo
      .query({ query: this.meQuery })
      .pipe(map((res: any) => res.data.me.company))
      .toPromise();

    return response;
  }

  public changePassword(newPassword) {
    const response = this.apollo
      .mutate({
        mutation: this.changePasswordQuery,
        variables: { newPassword },
      })
      .pipe(map((res: any) => res.data.changePassword))
      .toPromise();
    return response;
  }
}
