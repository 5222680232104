import { Component, OnInit } from '@angular/core';
import {EndCustomerOffer} from '../../../../notes';
import { endCustomerOffers } from '../../api/endCustomerOffers';
import {CONSTS_ROUTES} from '../../consts';
@Component({
  selector: 'app-end-customer-offers',
  templateUrl: './end-customer-offers.component.html',
  styleUrls: ['./end-customer-offers.component.css']
})
export class EndCustomerOffersComponent implements OnInit {
  public offers: EndCustomerOffer[] = endCustomerOffers;
  public routes = CONSTS_ROUTES;

  constructor() { }

  ngOnInit() {
  }

}
