import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-edit-form",
  templateUrl: "./edit-form.component.html",
  styleUrls: ["./edit-form.component.scss"],
  providers: [MessageService],
})
export class EditFormComponent implements OnInit {
  public editForm: FormGroup;

  constructor(private messageService: MessageService, public router: Router) {
    this.editForm = new FormGroup({
      email: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
      ]),
      msg: new FormControl("", [Validators.required, Validators.minLength(3)]),
      topic: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit(): void {}

  public async sendForm() {
    const property = this.editForm.value;
    console.log("edit", property);

    this.messageService.add({
      key: "cc",
      severity: "success",
      summary: "Edycja konta",
      detail: "prośba została wysłana",
    });
    this.resetForm();
  }
  private resetForm() {
    this.editForm.reset();
  }
}
